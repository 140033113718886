import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { registerUser } from 'src/api';
import { CreateAccountResponse } from 'src/models/general';
import { UserRegisterCredentials } from 'src/models/user';

type UseCreateUserProps = {
  onSuccess: (props: CreateAccountResponse) => void;
  isFreelancer: boolean;
};

interface ErrorData {
  email?: string;
}

export const useCreateUser = ({
  onSuccess,
  isFreelancer,
}: UseCreateUserProps) => {
  const {
    mutate, isError, error, isLoading,
  } = useMutation<
    CreateAccountResponse,
    AxiosError<ErrorData>,
    UserRegisterCredentials
  >(
    !isFreelancer ? 'register-user' : 'register-freelancer',
    (props) => registerUser(props),
    {
      onSuccess,
    },
  );

  return {
    mutate,
    isError,
    error,
    isLoading,
  };
};

export default useCreateUser;
