import {
  createContext, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { MemberModel } from 'src/models/user';


export type FavoriteTalentType = {
      favoriteDev: MemberModel | null;
      setFavoriteDev: Dispatch<SetStateAction<MemberModel | null>>;
      resetFavouriteDev: () => void;
  }

export const FavoriteTalentContext = createContext<FavoriteTalentType | null>(null);

interface Props {
  children: ReactNode;
}

const FavoriteTalentProvider: FC<Props> = ({ children }): ReactElement => {
  const [favoriteDev, setFavoriteDev] = useState<MemberModel | null>(null);

  const resetFavouriteDev = useCallback(() => {
    setFavoriteDev(null);
  }, []);

  const contextValues = useMemo(() => ({
    favoriteDev,
    setFavoriteDev,
    resetFavouriteDev,
  }), [favoriteDev, resetFavouriteDev]);

  useEffect(() => () => resetFavouriteDev(), [resetFavouriteDev]);

  return (
    <FavoriteTalentContext.Provider value={ contextValues }>
      { children }
    </FavoriteTalentContext.Provider>
  );
};

export default FavoriteTalentProvider;

export const useFavoriteTalent = () => {
  const value = useContext(FavoriteTalentContext);

  if (!value) {
    throw new Error('Context Provider is not defined');
  }
  return value;
};
