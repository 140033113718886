import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';


const defaultNS = 'translations';

i18n
  .use(HttpApi)
  .use(initReactI18next).init({
    lng: 'en',
    ns: ['translations'],
    fallbackLng: 'en',
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default defaultNS;
