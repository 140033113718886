import axios from 'axios';
import { ParsedQs } from 'qs';
import {
  ChangePasswordPayload,
  ClientProfilePayload,
  CurrencyModel,
  JobsPostingPayload,
  LanguageLinkModel,
  NewMemberPayload,
  PhoneCodeModel,
  SkillModel,
  ApproveClientPayload,
  ApproveFreelancerPayload,
} from 'src/models/formData';
import { APIRoutesEnum, LocalStorageEnum, SessionStorageEnum } from 'src/enums';
import {
  ClientModel,
  UserRegisterCredentials,
  LoginUserResponse,
  ResetPasswordResponse,
  ProviderModel,
  MemberModel,
  ClientUserModel,
  DedicatedClient,
  FreelancerInfoRequestCredentials,
  FreelancerInfoResponseCredentials,
  UserProject,
  FreelancerLanguage,
  UserEducation,
} from 'src/models/user';
import {
  CreateJobRequest,
  MatchedMemberItemModel,
  JobDurationModel,
  JobModel,
  JobModelWithClientData,
  LanguageModel,
  MatchesRequest,
  HireModel,
  PaginatedCandidates,
  CreateSkillsSuccess,
  JobPostingsResponse,
  FreelancerJobPostingsResponse,
  Country,
  ModalSearchModel,
  MatchingResponse,
  LoginResponse,
  NotificationModel,
  NotificationsSearchModel,
  ChatMessage,
  UserInfo,
  SendChatAttachmentProps,
  ChatAttachmentResponse,
  ConversationIDResponse,
  PaginatedFreelancers,
  AddMemberSkillMutation,
  AddMemberLanguageMutation,
  MemberLanguage,
  AddCertificateMutation,
  Certificate,
  DeleteMemberLanguage,
  MemberSkillResponse,
  DeleteMemberSkillMutation,
  EditMemberLanguageMutation,
  EditMemberSkillMutation,
  ConversationIDRequest,
  MatchedFreelancerChatRequest,
  Blog,
  Category,
  PaginatedBlogs,
  FreelancerDetails,
  MemberDetails,
  StackModel,
  SkillRequest,
} from 'src/models/general';
import { CustomJwtPayload, decodeToken } from 'src/utils';

const APPLICATION_JSON = 'application/json';
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/en`;
axios.defaults.headers.common['Content-Type'] = APPLICATION_JSON;
axios.defaults.headers.common.Accept = APPLICATION_JSON;

export const axiosInstance = axios.create();

export async function refreshAccessToken() {
  const { data } = await axios.post<{ access: string; }>('/token/refresh/', {
    refresh:
      localStorage.getItem(LocalStorageEnum.REFRESH_TOKEN) || sessionStorage.getItem(SessionStorageEnum.REFRESH_TOKEN),
  });

  return data.access;
}

export const registerUser = async ({
  isFreelancer, ...props
}: UserRegisterCredentials) => {
  const { data } = await axiosInstance.post<{ one_time_jwt: string; }>(
    isFreelancer ? APIRoutesEnum.REGISTER_FREELANCER : APIRoutesEnum.REGISTER,
    props,
  );
  return data;
};

export const registerFreelancer = async (postData: FreelancerInfoRequestCredentials) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { user_id: decodedUserId } = oneTimeJwt ? decodeToken(oneTimeJwt) : ({} as CustomJwtPayload);

  const { data } = await axiosInstance.post<FreelancerInfoResponseCredentials>(
    APIRoutesEnum.FREELANCER,
    {
      ...postData,
      user: decodedUserId,
    },
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const updateFreelancer = async (postData: FreelancerInfoRequestCredentials) => {
  const {
    freelancerId, ...payload
  } = postData;
  const { data } = await axiosInstance.put<FreelancerInfoResponseCredentials>(
    `${APIRoutesEnum.FREELANCER}${freelancerId}/`,
    payload,
  );

  return data;
};

export const deleteFreelancerProfile = async (id: number) => {
  const { data } = await axiosInstance.delete(`${APIRoutesEnum.FREELANCER}${id}`);
  return data;
};

export const editFreelancerProfileName = async ({
  id, newName,
}: {id: number;newName: string;}) => {
  const { data } = await axiosInstance.patch(`${APIRoutesEnum.FREELANCER}${id}/`, {
    profile_name: newName,
  });
  return data;
};

export const fetchFreelancer = async (id: number) => {
  const { data } = await axiosInstance.get(`freelancer/${id}`);
  return data;
};

export const createProject = async (payload: UserProject) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post(
    'projects/',
    payload,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const editProject = async (payload: UserProject, id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.put(
    `projects/${id}/`,
    payload,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const deleteProject = async (id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.delete(
    `projects/${id}/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};


export const getProjects = async (owner_id: number, owner_type: string) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.get(`projects/?owner_id=${owner_id}&owner_type=${owner_type}`, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });
  return data;
};

// education

export const createEducation = async (payload: UserEducation) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post(
    'education/',
    payload,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const editEducation = async (payload: UserEducation, id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.put(
    `education/${id}/`,
    payload,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const deleteEducation = async (id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.delete(
    `education/${id}/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};


export const getEducation = async (owner_id: number, owner_type: string) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);
  const { data } = await axiosInstance.get(
    `education/?owner_id=${owner_id}&owner_type=${owner_type}`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const createSkill = async ({
  skill, rating,
}: SkillRequest, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post(
    `freelancer/${freelancerId}/skill/`,
    {
      skill,
      rating,
    },
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const editSkill = async ({
  skill, rating,
}: SkillRequest, id: number, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.put(
    `freelancer/${freelancerId}/skill/${id}/`,
    {
      skill,
      rating,
    },
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const deleteSkill = async (id: number, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.delete(
    `freelancer/${freelancerId}/skill/${id}/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const getSkill = async (freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.get(
    `freelancer/${freelancerId}/skill/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const createLanguage = async (value: FreelancerLanguage, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post(
    `freelancer/${freelancerId}/language/`,
    value,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const getLangauge = async (freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.get(
    `freelancer/${freelancerId}/language/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const editLanguage = async (value: FreelancerLanguage, id: number, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.put(`freelancer/${freelancerId}/language/${id}/`, value, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });
  return data;
};

export const deleteLanguage = async (id: number, freelancerId: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.delete(
    `freelancer/${freelancerId}/language/${id}/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const deleteMemberLanguage = async ({
  teamId, memberId, languageId,
}: DeleteMemberLanguage) => {
  const { data } = await axiosInstance.delete(`/team/${teamId}/member/${memberId}/language/${languageId}/`);
  return data;
};

export const deleteCertificate = async (id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.delete(
    `certificates/${id}/`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

export const editCertificate = async (certificatePayload: AddCertificateMutation, id: number) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.put(
    `certificates/${id}/`,
    certificatePayload,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );

  return data;
};

export const getFreelancer = async () => {
  const { data } = await axiosInstance.get<PaginatedFreelancers>(APIRoutesEnum.FREELANCER);
  return data;
};

export const login = async ({
  email, password,
}: { email: string; password: string; }): Promise<LoginUserResponse> => {
  const { data } = await axiosInstance.post<{
    access: string;
    refresh: string;
  }>(APIRoutesEnum.LOGIN, {
    email,
    password,
  });
  return data;
};

export const resetPassword = async ({ email }: { email: string; }): Promise<ResetPasswordResponse> => {
  const { data } = await axiosInstance.post<{ message: string; }>(APIRoutesEnum.RESET_PASSWORD, {
    email,
  });
  return data;
};

export const createNewPassword = async ({
  password,
  confirmPassword,
  token,
}: {
  password: string;
  confirmPassword: string;
  token: string;
}): Promise<ResetPasswordResponse> => {
  const { data } = await axiosInstance.post<{ message: string; }>(`${APIRoutesEnum.RESET_PASSWORD_CONFRIM}/${token}/`, {
    new_password: password,
    new_password_confirm: confirmPassword,
  });
  return data;
};

/** Create Job Posting */
export const changePassword = async (postData: ChangePasswordPayload) => {
  const { data } = await axiosInstance.post<ResetPasswordResponse>(APIRoutesEnum.CHANGE_PASSWORD, postData);
  return data;
};

/** Create Job Posting */
export const createJobPosting = async (postData: JobsPostingPayload) => {
  const { data } = await axios.post<JobModel>(APIRoutesEnum.JOB_POSTINGS, postData, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT)}`,
    },
  });
  return data;
};

/** Add New Skill */
export const addNewSkill = async ({ title }: { title: string; }) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post<SkillModel[]>(APIRoutesEnum.SKILLS, [
    {
      title,
    },
  ], {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });
  return data;
};

export const addNewSkills = async (newSkills: { title: string; }[]) => {
  const { data } = await axios.post<CreateSkillsSuccess[]>(APIRoutesEnum.SKILLS, newSkills, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT)}`,
    },
  });
  return data;
};

/** Add Skill to Member */
export const addMemberSkill = async ({
  teamId, memberId, skillPayload,
}: AddMemberSkillMutation) => {
  const { data } = await axiosInstance.post<SkillModel>(`/team/${teamId}/member/${memberId}/skill/`, skillPayload);
  return data;
};

export const deleteMemberSkill = async ({
  teamId, memberId, skillId,
}: DeleteMemberSkillMutation) => {
  const { data } = await axiosInstance.delete(`/team/${teamId}/member/${memberId}/skill/${skillId}/`);
  return data;
};

/** Edit Skill */
export const editMemberSkill = async ({
  teamId, memberId, skillPayload, skillId,
}: EditMemberSkillMutation) => {
  const { data } = await axiosInstance.put<SkillModel>(`/team/${teamId}/member/${memberId}/skill/${skillId}/`, skillPayload);
  return data;
};


/** Get clients */
export const fetchClients = async () => {
  const { data } = await axiosInstance.get<ClientModel[]>(`${APIRoutesEnum.CLIENT}`);
  return data;
};

/** Get Skills */
export const fetchSkills = async () => {
  const { data } = await axiosInstance.get<SkillModel[]>(APIRoutesEnum.SKILLS);
  return data;
};

/** Get Member Skills */
export const fetchMemberSkills = async (teamId: number, memberId: number) => {
  const { data } = await axiosInstance.get<MemberSkillResponse[]>(`/team/${teamId}/member/${memberId}/skill/`);
  return data;
};

/** Get Seniorities */
export const fetchSeniorities = async () => {
  const { data } = await axiosInstance.get<SkillModel[]>(APIRoutesEnum.SENIORITY_LEVELS);
  return data;
};

/** Get Professions */
export const fetchProfessions = async () => {
  const { data } = await axiosInstance.get<SkillModel[]>(APIRoutesEnum.PROFESSIONS);
  return data;
};

/** Get Job Durations */
export const fetchJobDurations = async () => {
  const { data } = await axiosInstance.get<SkillModel[]>(APIRoutesEnum.JOB_DURATIONS);
  return data;
};

/** Get Job Currencies */
export const fetchCurrencies = async () => {
  const { data } = await axiosInstance.get<CurrencyModel[]>(APIRoutesEnum.CURRENCIES);
  return data;
};

/** Get COuntries */
export const fetchCountries = async () => {
  const { data } = await axiosInstance.get<Country[]>(APIRoutesEnum.COUNTRIES);
  return data;
};

/** Get Client By ID */
export const fetchClientById = async (userId: number) => {
  const { data } = await axiosInstance.get<ClientModel>(`${APIRoutesEnum.CLIENT}${userId}/`);
  return data;
};

/** Create Client */
export const createClient = async (userId: number) => {
  const { data } = await axiosInstance.post<ClientModel>(`${APIRoutesEnum.CLIENT}/`, {
    user_id: userId,
  });
  return data;
};

/** Update Client By ID */
export const updateClientById = async (userId: number, postData: ClientProfilePayload | FormData) => {
  const { data } = await axiosInstance.patch<ClientModel>(`${APIRoutesEnum.CLIENT}${userId}/`, postData);
  return data;
};

/** Add Certificates to User */
export const addCertificate = async (certificatePayload: AddCertificateMutation) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post('/certificates/', certificatePayload, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });
  return data;
};

/** Delete Skill */
export const deleteCertificate1 = async (id: number) => {
  const { data } = await axiosInstance.delete(`/certificates/${id}`);
  return data;
};

/** Get Users Certificates */
export const fetchUserCertificates = async (id: number, owner_type: string) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.get<Certificate[]>(
    `certificates/?owner_id=${id}&owner_type=${owner_type}`,
    {
      headers: {
        Accept: APPLICATION_JSON,
        Authorization: `Bearer ${oneTimeJwt}`,
      },
    },
  );
  return data;
};

/** Add Language to Member */
export const addMemberLanguage = async ({
  teamId, memberId, languagePayload,
}: AddMemberLanguageMutation) => {
  const { data } = await axiosInstance.post<MemberLanguage>(`/team/${teamId}/member/${memberId}/language/`, languagePayload);
  return data;
};

/** Edit Language to Member */
export const editMemberLanguage = async ({
  teamId, memberId, languagePayload, languageId,
}: EditMemberLanguageMutation) => {
  const { data } = await axiosInstance.put<MemberLanguage>(
    `/team/${teamId}/member/${memberId}/language/${languageId}/`,
    languagePayload,
  );
  return data;
};

export const fetchLanguages = async () => {
  const { data } = await axiosInstance.get<LanguageModel[]>(APIRoutesEnum.LANGUAGES);
  return data;
};

/** Get Member Languages */
export const fetchMemberLanguages = async (teamId: number, memberId: number) => {
  const { data } = await axiosInstance.get<MemberLanguage[]>(`/team/${teamId}/member/${memberId}/language/`);
  return data;
};

export const fetchEducationLevel = async () => {
  const { data } = await axiosInstance.get<JobDurationModel[]>(APIRoutesEnum.EDUCATION_LEVELS);
  return data;
};

export const fetchJobById = async (jobId: string | null) => {
  const { data } = await axiosInstance.get<JobModelWithClientData>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/`);
  return data;
};

export const fetchNotification = async (filter: NotificationsSearchModel) => {
  const { data } = await axiosInstance.get(APIRoutesEnum.NOTIFICATION, {
    params: filter,
  });
  return data;
};

export const fetchAllNotification = async () => {
  const { data } = await axiosInstance.get(APIRoutesEnum.NOTIFICATION);
  return data;
};

export const seenNotificationRequest = async (notificationId?: number) => {
  await axiosInstance.patch<NotificationModel>(`${APIRoutesEnum.NOTIFICATION}${notificationId}`, {
    seen: true,
  });
};

export const notificationsMarkAllAsReadRequest = async () => {
  await axiosInstance.get(`${APIRoutesEnum.NOTIFICATIONS_MARK_ALL_AS_READ}`);
};

export const deleteNotificationRequest = async (notificationId?: number) => {
  await axiosInstance.delete<NotificationModel>(`${APIRoutesEnum.DELETE_NOTIFICATION}${notificationId}`);
};

export const createJobRequest = async (payload: CreateJobRequest) => {
  const { data } = await axiosInstance.post<JobModel>(APIRoutesEnum.JOB_POSTINGS, payload);
  return data;
};

export const updateJobRequest = async (payload: CreateJobRequest, jobId: string) => {
  const { data } = await axiosInstance.put<JobModel>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/`, payload);
  return data;
};

export const fetchLanguageLinks = async () => {
  const { data } = await axiosInstance.get<LanguageLinkModel[]>(APIRoutesEnum.LANGUAGE_LINKS);
  return data;
};

export const fetchPhoneCodes = async () => {
  const { data } = await axiosInstance.get<PhoneCodeModel[]>(APIRoutesEnum.PHONE_CODES);
  return data;
};

export const fetchJobs = async (filter: ParsedQs): Promise<JobPostingsResponse | FreelancerJobPostingsResponse> => {
  const { data } = await axiosInstance.get<JobPostingsResponse>(`${APIRoutesEnum.JOB_POSTINGS}`, {
    params: filter,
  });
  return data;
};

export const changeJobStatus = async (params: Partial<JobModel>) => {
  const { id } = params;
  const { data } = await axiosInstance.patch<JobModelWithClientData>(`${APIRoutesEnum.JOB_POSTINGS}${id}/`, params);
  return data;
};

export const deleteJob = async (id: string) => {
  const { data } = await axiosInstance.delete<void>(`${APIRoutesEnum.JOB_POSTINGS}${id}/`);
  return data;
};

/** Add New Member To SP Team */
export const addNewMember = async (teamId: number, postData: NewMemberPayload) => {
  const { data } = await axiosInstance.post<MemberModel>(`${APIRoutesEnum.NEW_MEMBER}${teamId}/member/`, postData);
  return data;
};

/** Edit SP Team Member */
export const editMember = async (teamId: number, memberId: number, postData: NewMemberPayload) => {
  const { data } = await axiosInstance.patch<MemberModel>(
    `${APIRoutesEnum.NEW_MEMBER}${teamId}/member/${memberId}/`,
    postData,
  );
  return data;
};

/** Get SP Team Member */
export const fetchMember = async (teamId: number, memberId: number) => {
  const { data } = await axiosInstance.get<MemberModel>(`${APIRoutesEnum.NEW_MEMBER}${teamId}/member/${memberId}/`);
  return data;
};

/** Get Top Talents */
export const fetchTalents = async () => {
  const { data } = await axiosInstance.get<MemberModel[]>(APIRoutesEnum.TALENTS);
  return data;
};

/** Upload CV */
export const uploadPicture = async (postData: FormData) => {
  const { data } = await axiosInstance.put<{
    file_name: string;
    file_url: string;
  }>(`${APIRoutesEnum.FILE}`, postData);
  return data;
};

/** Add Member CVs */
export const addMemberCv = async (postData: FormData) => {
  const { data } = await axiosInstance.post<{
    file_name: string;
    file_url: string;
  }>(`${APIRoutesEnum.CV}`, postData);
  return data;
};

export const addFreelancerFile = async (postData: FormData) => {
  const { data } = await axiosInstance.patch(APIRoutesEnum.FREELANCER_FILE, postData, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT)}`,
    },
  });

  return data;
};

/** Add Member CVs */
export const updateMemberCv = async (postData: FormData, id: number) => {
  const { data } = await axiosInstance.patch(`${APIRoutesEnum.CV}${id}/`, postData);
  return data;
};

export const deleteMemberCv = async (id: number) => {
  await axiosInstance.delete<void>(`${APIRoutesEnum.CV}${id}/`);
};

/** Download cv as pdf */
export const fetchPdf = async (role: string, id: number | undefined) => {
  const { data } = await axiosInstance.get(`pdf/${role}/${id}/`, {
    responseType: 'blob',
  });
  return data;
};


/** Get SP Profile */
export const getProviderProfile = async () => {
  const { data } = await axiosInstance.get<ProviderModel>(`${APIRoutesEnum.PROVIDER_ME}`);
  return data;
};

/** Get Client Profile */
export const getClientProfile = async () => {
  const { data } = await axiosInstance.get<ClientUserModel>(`${APIRoutesEnum.CLIENT_ME}`);
  return data;
};

/** Get Dedicated Clients */
export const getClients = async (query: URLSearchParams) => {
  const { data } = await axiosInstance.get<DedicatedClient[]>(
    `${APIRoutesEnum.CLIENT_DEDICATED}`,
    {
      params: query,
    },
  );
  return data;
};

/** Get Dedicated Free.ancers */
export const getFreelancers = async (query: URLSearchParams) => {
  const { data } = await axiosInstance.get<PaginatedFreelancers>(APIRoutesEnum.FREELANCER, {
    params: query,
  });

  return data;
};

/** Get Requested Clients */
export const getRequestedClients = async (query: { search: string; page: string; limit: number; }) => {
  const { data } = await axiosInstance.get(`${APIRoutesEnum.PENDING_CLIENTS}`, {
    params: query,
  });
  return data;
};

/** Get Requested Freelancers */
export const getRequestedFreelancers = async (query: ParsedQs) => {
  const { data } = await axiosInstance.get(APIRoutesEnum.PENDING_FREELANCERS, {
    params: query,
  });
  return data;
};

/** Approve or reject non activated clients */
export const approveRequestedClient = async (approveData: ApproveClientPayload) => {
  const { data } = await axiosInstance.post(`${APIRoutesEnum.PENDING_CLIENTS}`, approveData);
  return data;
};

/** Approve or reject non activated Freelancers */
export const approveRequestedFreelancer = async (approveData: ApproveFreelancerPayload) => {
  const { data } = await axiosInstance.post(`${APIRoutesEnum.PENDING_FREELANCERS}`, approveData);

  return data;
};

/** Get Client Profile With Id */
export const getClientProfileWithId = async (id: string) => {
  const { data } = await axiosInstance.get<ClientModel>(`${APIRoutesEnum.CLIENT}${id}/`);
  return data;
};

export const getFreelancerProfileWithId = async (id: string) => {
  const { data } = await axiosInstance.get<FreelancerInfoResponseCredentials>(`${APIRoutesEnum.FREELANCER}${id}/`);
  return data;
};

/** Get All Clients */
export const getAllClients = async () => {
  const { data } = await axiosInstance.get<ClientModel[]>(APIRoutesEnum.CLIENT);
  return data;
};

export const getMatchedClients = async (id: string) => {
  const { data } = await axiosInstance.get<ClientModel[]>(APIRoutesEnum.CLIENT, {
    params: {
      super_client: id,
    },
  });
  return data;
};

export const fetchMatchedMembers = async (jobId: number, statusToSend?: any) => {
  const { data } = await axiosInstance.get<MatchedMemberItemModel[]>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/hires/`, {
    params: statusToSend,
  });
  return data;
};

export const fetchMembersToMatch = async (jobId: number, filters: ModalSearchModel) => {
  const { data } = await axiosInstance.get<MatchingResponse>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/matching/`, {
    params: filters,
  });
  return data;
};

export const fetchFreelancersToMatch = async (jobId: number, params: ModalSearchModel) => {
  const { data } = await axiosInstance.get<PaginatedFreelancers>(
    `${APIRoutesEnum.JOB_POSTINGS}${jobId}/freelancer-matching/`,
    {
      params,
    },
  );
  return data;
};

export const createMatches = async (jobId: number, body: { hires: MatchesRequest[]; }) => {
  const { data } = await axiosInstance.post<{ detail: string; }>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/hires/`, body);
  return data;
};

export const deleteMatched = async (jobId: number, memberId: number) => {
  await axiosInstance.delete(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/hires/${memberId}/`);
};

export const fetchJobMatches = async (jobId: string, query?: ParsedQs) => {
  const { data } = await axiosInstance.get<HireModel[]>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/hires/`, {
    params: query,
  });
  return data;
};

/** Delete Candidate With SP And Candidate IDS */
export const deleteCandidate = async (userId: number, memberId: number) => {
  await axiosInstance.delete<void>(`${APIRoutesEnum.CANDIDATES}${userId}/member/${memberId}/`);
};

export const deleteFreelancer = async (freelancerId: number) => {
  await axiosInstance.delete(`${APIRoutesEnum.FREELANCER}${freelancerId}/`);
};

export const changeMatchedStatus
= async (jobId: string, id: number, postData: { status: string; freelancer_status?: string; admin_status?: string; }) => {
  const { data } = await axiosInstance.patch<HireModel>(`${APIRoutesEnum.JOB_POSTINGS}${jobId}/hires/${id}/`, postData);
  return data;
};

export const sendEmail = async (body: { email: string; }) => {
  await axiosInstance.post<HireModel>(`${APIRoutesEnum.SEND_EMAIL}/`, body);
};

export const verifyToken = async (token: string) => {
  const { data } = await axiosInstance.get<LoginResponse>(`${APIRoutesEnum.VERIFY_TOKEN}${token}/`);
  return data;
};

export const fetchCandidates = async (serviceProviderId: number, query: URLSearchParams) => {
  const { data } = await axiosInstance.get<PaginatedCandidates>(
    `${APIRoutesEnum.CANDIDATES}${serviceProviderId}/member/`,
    {
      params: query,
    },
  );
  return data;
};

export const deleteSelectedNotifications = async (ids: number[]) => {
  await axiosInstance.delete<void>(`${APIRoutesEnum.DELETE_SELECTED_NOTIFICATIONS}`, {
    data: {
      ids,
    },
  });
};

export const fetchJobChat = async (jobId: string | number) => {
  const { data } = await axiosInstance.get<ChatMessage[]>(`${APIRoutesEnum.CHAT}${jobId}`);
  return data;
};

export const fetchUnmatchedFreelancerChat = async (freelancerId?: number) => {
  const { data } = await axiosInstance.get<ChatMessage[]>(`${APIRoutesEnum.UNMATCHED_FREELANCER_CHAT}${freelancerId}`);
  return data;
};

export const fetchMatchedFreelancerChat = async ({
  jobId,
  freelancerUserId,
}: MatchedFreelancerChatRequest) => {
  const { data } = await axiosInstance.get<ChatMessage[]>(APIRoutesEnum.MATCHED_FREELANCER_CHAT, {
    params: {
      ...(jobId
        ? {
          job_posting_id: jobId,
        }
        : {}),
      ...(freelancerUserId
        ? {
          freelancer_user_id: freelancerUserId,
        }
        : {}),
    },
  });
  return data;
};

export const getUserInfo = async () => {
  const { data } = await axiosInstance.get<UserInfo>(APIRoutesEnum.USER_INFO);
  return data;
};

export const getUnseenChatsCount = async (ids: number[]) => {
  const { data } = await axiosInstance.post<{ unread_conversations: number[]; }>(APIRoutesEnum.UNSEEN_CHATS_COUNT, {
    conversation_ids: ids,
  });

  return data;
};

export const sendChatAttachment = async (props: SendChatAttachmentProps) => {
  const formData = new FormData();
  formData.append('attachment', props.attachment);
  formData.append('conversation_id', String(props.conversationId));

  const { data } = await axiosInstance.post<ChatAttachmentResponse>(APIRoutesEnum.CHAT_ATTACHMENT, formData);
  return data;
};

export const getConversationId = async ({
  jobId, freelancerUserId,
}: ConversationIDRequest) => {
  const { data } = await axiosInstance.get<ConversationIDResponse>(APIRoutesEnum.CHAT_CONVERSATION_ID, {
    params: {
      ...(jobId
        ? {
          job_id: jobId,
        }
        : {}),
      ...(freelancerUserId
        ? {
          freelancer_user_id: freelancerUserId,
        }
        : {}),
    },
  });
  return {
    ...data,
    ...(jobId
      ? {
        jobId,
      }
      : {}),
    ...(freelancerUserId
      ? {
        freelancerUserId,
      }
      : {}),
  };
};

export const markConversationAsRead = async (conversationId: number) => {
  axiosInstance.get(`${APIRoutesEnum.MARK_CONVERSATION_AS_READ}/${conversationId}/`);
};

export const getChatAttachment = async (attachmentId: number) => {
  const { data } = await axiosInstance.get<{
    attachment_url: string;
    attachment_name: string;
  }>(`${APIRoutesEnum.CHAT_ATTACHMENT}/${attachmentId}/`);
  return data;
};

export const createSocials = async (values: {calendly_link: string;}) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.post(APIRoutesEnum.SOCIALS, values, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });
  return data;
};

export const editSocials = async (values: {calendly_link: string | null;}, id: number | undefined) => {
  const { data } = await axiosInstance.put(`${APIRoutesEnum.SOCIALS}${id}`, values);
  return data;
};

export const getUserSocials = async (id: number | undefined) => {
  const oneTimeJwt = localStorage.getItem(LocalStorageEnum.ONE_TIME_JWT);

  const { data } = await axiosInstance.get<{
    id: number;
    user: number;
    calendly_link: string;
  }>(`${APIRoutesEnum.SOCIALS}${id}`, {
    headers: {
      Accept: APPLICATION_JSON,
      Authorization: `Bearer ${oneTimeJwt}`,
    },
  });

  return data;
};

export const getBlogs = async (query: URLSearchParams) => {
  const { data } = await axiosInstance.get<PaginatedBlogs>(APIRoutesEnum.BLOGS, {
    params: query,
  });

  return data;
};

export const getBlog = async (id: number | null) => {
  const { data } = await axiosInstance.get<Blog>(`${APIRoutesEnum.BLOGS}${id}/`);

  return data;
};

export const createBlog = async (value: FormData) => {
  const { data } = await axiosInstance.post(APIRoutesEnum.BLOGS, value);

  return data;
};

export const editBlog = async (value: FormData, id: number) => {
  const { data } = await axiosInstance.put(`${APIRoutesEnum.BLOGS}${id}/`, value);

  return data;
};

export const deleteBlog = async (id: number | null) => {
  const { data } = await axiosInstance.delete(`${APIRoutesEnum.BLOGS}${id}/`);

  return data;
};

export const getCategories = async () => {
  const { data } = await axiosInstance.get<Category[]>(`${APIRoutesEnum.BLOGS}category/`);

  return data;
};

export const getUser = async (hash: string) => {
  const { data } = await axiosInstance.get<FreelancerDetails>(`freelancer/sharing/${hash}/`);

  return data;
};

export const getMember = async (hash: string) => {
  const { data } = await axiosInstance.get<MemberDetails>(`team/${1}/member/sharing/${hash}/`);

  return data;
};

// get stacks
export const getStacks = async () => {
  const { data } = await axiosInstance.get<StackModel[]>(APIRoutesEnum.STACK);
  return data;
};
