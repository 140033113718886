import {
  createContext, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES, LocalStorageEnum, RoleEnum } from 'src/enums';
import { useAuth } from '../authContext';

export interface LocalesContextInterface {
        locale: LOCALES;
        setLocale: Dispatch<SetStateAction<LOCALES>>;
        changeLocale: (lang: LOCALES) => void;
      }

const getLocaleFromLocalStorage = () => {
  const locale = localStorage.getItem(LocalStorageEnum.LOCALE);
  const host = window.location.host.split('.');
  const isItalian = host[host.length - 1] === 'it';
  if (!locale && isItalian) {
    return LOCALES.IT;
  }
  return locale === LOCALES.IT ? LOCALES.IT : LOCALES.EN;
};

export const LocalesContext = createContext<LocalesContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const LocalesContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [locale, setLocale] = useState(LOCALES.EN);

  const cookieAccepted = localStorage.getItem(LocalStorageEnum.COOKIE_ACCEPTED);

  const { role } = useAuth();

  const { i18n } = useTranslation();

  const changeLocale = useCallback((lang: LOCALES) => {
    if (cookieAccepted) {
      localStorage.setItem(LocalStorageEnum.LOCALE, lang);
    }
    i18n.changeLanguage(lang);
    setLocale(lang);
  }, [i18n, cookieAccepted]);

  const contextValues = useMemo(() => ({
    locale,
    setLocale,
    changeLocale,
  }), [locale, changeLocale]);

  useEffect(() => {
    if (role === RoleEnum.PROVIDER && locale === LOCALES.IT) {
      changeLocale(LOCALES.EN);
    }
  }, [role, changeLocale, locale]);

  useEffect(() => {
    changeLocale(getLocaleFromLocalStorage());
  }, [changeLocale]);

  return (
    <LocalesContext.Provider value={ contextValues }>
      { children }
    </LocalesContext.Provider>
  );
};

export default LocalesContextProvider;

export const useLocales = () => {
  const value = useContext(LocalesContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
