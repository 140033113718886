import axios, { AxiosError } from 'axios';
import {
  FC, ReactElement, ReactNode, useEffect, useMemo,
} from 'react';
import { axiosInstance } from 'src/api';
import { useAlertModal } from 'src/context/alertMessageContext';
import { getErrorMesaagesList } from 'src/utils';

interface Props {
  children: ReactNode;
}

const WithErrorAxios: FC<Props> = ({ children }): ReactElement => {
  const { addAlertMessages } = useAlertModal();


  const axiosInstanceResponsetInterceptor = useMemo(() => axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        const messagesList = getErrorMesaagesList(error);
        addAlertMessages(messagesList);
      }
      /* eslint no-underscore-dangle: 0 */
      return Promise.reject(error);
    },
  ), [addAlertMessages]);

  useEffect(() => () => {
    axiosInstance.interceptors.response.eject(axiosInstanceResponsetInterceptor);
  }, [axiosInstanceResponsetInterceptor]);

  return <div>{ children }</div>;
};

export default WithErrorAxios;
