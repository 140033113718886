import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import 'src/i18n/i18n';
import App from './App';
import CircularLoading from './components/UI/atoms/loading';
import AuthContextProvider from './context/authContext';
/* eslint-disable react/jsx-indent */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<QueryClientProvider client={ queryClient }>
    <BrowserRouter>
      <AuthContextProvider>
        <Suspense fallback={ <CircularLoading /> }>
          <App />
        </Suspense>
      </AuthContextProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={ false } />
            </QueryClientProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
