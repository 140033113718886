import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { styled } from '@mui/system';
import { useAlertModal } from 'src/context/alertMessageContext';
import { MessageModel } from 'src/models/error';
import { ALERT_TYPES } from 'src/enums';

const CloseButton = styled(IconButton)({
  background: 'transparent',
  color: 'white',
  '&:hover': {
    background: 'transparent',
  },
});

type Props = {
  message: MessageModel;
  closeAlert: (id: string) => void;
};

const SnackbarMessage: FC<Props> = ({
  message, closeAlert,
}) => {
  const handleAlertClose = () => {
    closeAlert(message.id);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      closeAlert(message.id);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [message, closeAlert]);

  const backgroundColor
    = message.type === ALERT_TYPES.ERROR ? 'mainError' : 'green';

  return (
    <Alert
      onClose={ handleAlertClose }
      severity={ message.type }
      action={ (
        <CloseButton size='small' onClick={ handleAlertClose }>
          <ClearRoundedIcon />
        </CloseButton>
      ) }
      sx={ {
        backgroundColor: `${backgroundColor}`,
        position: 'fixed',
        right: 20,
        bottom: 20,
        zIndex: 1000,
      } }
    >
      <Typography color='primary.light'>{ message.message }</Typography>
    </Alert>
  );
};

const AlertMessage = () => {
  const {
    alertMessages, removeAlertMessage,
  } = useAlertModal();

  return (
    <Stack
      sx={ {
        position: 'fixed',
        right: 20,
        bottom: 20,
        zIndex: 1000,
      } }
      direction='column'
      spacing={ 2 }
    >
      { alertMessages.length
        ? alertMessages.map((msg) => (
          <SnackbarMessage
            key={ msg.id }
            closeAlert={ removeAlertMessage }
            message={ msg }
          />
        ))
        : null }
    </Stack>
  );
};

export default AlertMessage;
