import { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import CircularLoading from 'src/components/UI/atoms/loading';
import DynamicUserDescriptionWrapper from 'src/components/UI/organisms/dynamicUserDescriptionWrapper';
import {
  LoginAbsoluteRoutes,
  PrivateClientAbsoluteRoutes,
  PublicClientAbsoluteRoutes,
  PublicFreelancerAbsoluteRoutes,
  ServicePrivateAbsoluteRoutes,
  PrivateFreelancerAbsoluteRoutes,
} from 'src/enums';
import WithDynamicRedirect from 'src/hoc/withDynamicRedirect';
import WithAxiosResponseInterceptor from 'src/hoc/withOneTimeTokenAxios';

/** Pages */
const VerifyToken = lazy(() => import('src/components/pages/verifyToken'));
const ServicePrivateRoutes = lazy(() => import('src/components/routes/servicePrivateRoutes'));
const FreelancerPrivateRoutes = lazy(() => import('src/components/routes/freelancerPrivateRoutes'));
const ClientUserPublic = lazy(() => import('src/components/layout/clientUserPublic'));
const LoginLayout = lazy(() => import('src/components/layout/loginLayout'));
const Landing = lazy(() => import('src/components/pages/landingpage'));
const ClientPrivateRoutes = lazy(() => import('src/components/routes/clientPrivateRoutes'));
const Policy = lazy(() => import('src/components/pages/policy'));
const Terms = lazy(() => import('src/components/pages/terms'));
const ClientAuthContent = lazy(() => import('src/components/UI/organisms/auth/clientLogin'));
const ForgetPasswordContent = lazy(() => import('src/components/UI/organisms/auth/forgetPasswordContent'));
const CheckEmailPage = lazy(() => import('src/components/pages/checkEmail'));
const CreateNewPasswordContent = lazy(() => import('src/components/UI/organisms/auth/createNewPasswordContent'));
const Notifications = lazy(() => import('src/components/pages/notifications/index'));
const Chats = lazy(() => import('src/components/UI/organisms/chats/index'));
const NewJobRequestPage = lazy(() => import('src/components/pages/newJobRequest'));
const UserDescriptionPage = lazy(() => import('src/components/pages/userDescription'));
const ClientMatches = lazy(() => import('src/components/UI/organisms/matches'));
const ClientJobDetailsOrganism = lazy(() => import('src/components/UI/organisms/clientJobDetails'));
const ClientChat = lazy(() => import('src/components/UI/organisms/clientChat'));
const ClientProfilePage = lazy(() => import('src/components/pages/clientProfilePage'));
const ClientOpenRequestsPage = lazy(() => import('src/components/pages/clientOpenRequests'));
const ClientJobDetailsPage = lazy(() => import('src/components/pages/clientJobDetailsPage'));
const JobRequestsTemplate = lazy(() => import('src/components/pages/jobDetails'));
const ServiceProviderProfilePage = lazy(() => import('src/components/pages/serviceProviderProfile'));
const JobDetails = lazy(() => import('src/components/pages/jobDetailsMatches'));
const ServiceProviderMatching = lazy(() => import('src/components/UI/organisms/matches/serviceProviderMatching'));
const JobDetailsTemplate = lazy(() => import('src/components/templates/jobDetails'));
const Matches = lazy(() => import('src/components/pages/stacks/matches'));
const SalaryRates = lazy(() => import('src/components/pages/salaryRates'));
const Stacks = lazy(() => import('src/components/pages/stacks'));
const AddNewMember = lazy(() => import('src/components/pages/addMember'));
const EditMember = lazy(() => import('src/components/pages/editMember'));
const ClientDetailsPage = lazy(() => import('src/components/pages/clientDetailsPage'));
const FreelancerDetailsPage = lazy(() => import('src/components/pages/freelancerDetailsPage'));
const FreelancerDetails = lazy(() => import('src/components/UI/organisms/freelancerDetails'));
const UnmatchedFreelancerChat = lazy(() => import('src/components/UI/organisms/unmatchedFreelancerChat'));
const MatchedFreelancerChat = lazy(() => import('src/components/UI/organisms/matchedFreelancerChat'));
const CreateAccount = lazy(() => import('src/components/pages/account/createAccount'));
// const LookingFor = lazy(() => import('src/components/pages/lookingFor'));
const ClientEmailVerificationPage = lazy(() => import('src/components/pages/clientEmailVerification'));
const FreelancerEmailVerificationPage = lazy(() => import('src/components/pages/freelancerEmailVerification'));
const Clients = lazy(() => import('src/components/pages/clients'));
const Freelancers = lazy(() => import('src/components/pages/freelancers'));
const CreateFreelanceRaccount = lazy(() => import('src/components/pages/account/createFreelancerAccount'));
const FreelancerInfo = lazy(() => import('src/components/pages/freelancerInfo'));
const FreelancerProfile = lazy(() => import('src/components/pages/freelancerProfilePage'));
const FreelancerOpenRequestsPage = lazy(() => import('src/components/pages/freelancerOpenRequests'));
const FreelancerJobDetailsPage = lazy(() => import('src/components/pages/freelancerJobDetailsPage'));
const FreelancerJobDetailsOrganism = lazy(() => import('src/components/UI/organisms/freelancerJobDetails'));
const FreelancerMatches = lazy(() => import('src/components/UI/organisms/matches/freelancerMatches'));
const CareerTips = lazy(() => import('src/components/UI/organisms/careerTips'));
const Blogs = lazy(() => import('src/components/pages/blogs'));

const WithRedirectToJobDetails = WithDynamicRedirect(ServicePrivateAbsoluteRoutes.JOB_DETAILS_BACKUP);
const WithRedirectToClientobDetails = WithDynamicRedirect(PrivateClientAbsoluteRoutes.CLIENT_JOB_DETAILS_BACKUP);
const WithRedirectToFreelancertobDetails = WithDynamicRedirect(PrivateFreelancerAbsoluteRoutes.FREELANCER_JOB_DETAILS_BACKUP);
const AppRouter: FC = () => (
  <Suspense fallback={ <CircularLoading /> }>
    <Routes>
      <Route path='/client/public/verify-token/:token' element={ <VerifyToken /> } />
      <Route path='/client/public' element={ <ClientUserPublic /> }>
        <Route path={ PublicClientAbsoluteRoutes.CLIENT_CREATE_ACCOUNT } element={ <CreateAccount /> } />
        { /* <Route path={ PublicClientAbsoluteRoutes.CLIENT_LOOKING_FOR } element={ <LookingFor /> } /> */ }
      </Route>
      <Route path='/freelancer/public/verify-token/:token' element={ <VerifyToken isFreelancer /> } />
      <Route path='/freelancer/public' element={ <ClientUserPublic /> }>
        <Route
          path={ PublicFreelancerAbsoluteRoutes.FREELANCER_CREATE_ACCOUNT }
          element={ <CreateFreelanceRaccount /> }
        />
        <Route
          path={ PublicFreelancerAbsoluteRoutes.FREELANCER_INFO }
          element={ (
            <WithAxiosResponseInterceptor>
              <FreelancerInfo />
            </WithAxiosResponseInterceptor>
          ) }
        />
      </Route>
      <Route path='login' element={ <LoginLayout /> }>
        <Route index element={ <ClientAuthContent /> } />
        <Route path={ LoginAbsoluteRoutes.FORGET_PASSWORD } element={ <ForgetPasswordContent /> } />
        <Route path={ LoginAbsoluteRoutes.CHECK_EMAIL } element={ <CheckEmailPage /> } />
        <Route path={ LoginAbsoluteRoutes.RESET_PASSWORD } element={ <CreateNewPasswordContent /> } />
        <Route path={ LoginAbsoluteRoutes.CLIENT_VERIFY_EMAIL } element={ <ClientEmailVerificationPage /> } />
        <Route path={ LoginAbsoluteRoutes.FREELANCER_VERIFY_EMAIL } element={ <FreelancerEmailVerificationPage /> } />
      </Route>
      <Route path='client' element={ <ClientPrivateRoutes /> }>
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_PROFILE }
          element={ <ClientProfilePage /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_NOTIFICATIONS }
          element={ <Notifications /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_CHATS }
          element={ <Chats /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_REQUESTS }
          element={ <ClientOpenRequestsPage /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_NEW_JOB_REQUEST }
          element={ <NewJobRequestPage /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_USER_DESCRIPTION }
          element={ <UserDescriptionPage /> }
        />
        <Route
          path={ PrivateClientAbsoluteRoutes.CLIENT_JOBDETAILS }
          element={ <ClientJobDetailsPage /> }
        >
          <Route index element={ <WithRedirectToClientobDetails /> } />
          <Route path={ PrivateClientAbsoluteRoutes.CLIENT_JOB_DETAILS_BACKUP } element={ <ClientJobDetailsOrganism /> } />
          <Route path={ PrivateClientAbsoluteRoutes.CLIENT_JOB_MATCHES } element={ <ClientMatches /> } />
          <Route path={ PrivateClientAbsoluteRoutes.CLIENT_JOB_CHAT } element={ <ClientChat /> } />
        </Route>
      </Route>
      <Route path='freelancer' element={ <FreelancerPrivateRoutes /> }>
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_PROFILE } element={ <FreelancerProfile /> } />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_NOTIFICATIONS } element={ <Notifications /> } />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_CHATS } element={ <Chats /> } />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_CAREER_TIPS } element={ <CareerTips /> } />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_REQUESTS } element={ <FreelancerOpenRequestsPage /> } />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_CHAT } element={ <UnmatchedFreelancerChat /> } />
        <Route
          path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_USER_DESCRIPTION }
          element={ <UserDescriptionPage /> }
        />
        <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_JOBDETAILS } element={ <FreelancerJobDetailsPage /> }>
          <Route index element={ <WithRedirectToFreelancertobDetails /> } />
          <Route
            path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_JOB_DETAILS_BACKUP }
            element={ <FreelancerJobDetailsOrganism /> }
          />
          <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_JOB_MATCHES } element={ <FreelancerMatches /> } />
          <Route path={ PrivateFreelancerAbsoluteRoutes.FREELANCER_CHAT } element={ <MatchedFreelancerChat /> } />
        </Route>
      </Route>
      <Route path='service' element={ <ServicePrivateRoutes /> }>
        <Route
          path={ ServicePrivateAbsoluteRoutes.HOME }
          element={ <JobRequestsTemplate /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.PROFILE }
          element={ <ServiceProviderProfilePage /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.MATCHES }
          element={ <Matches /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.SALARY_RATES }
          element={ <SalaryRates /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.STACKS }
          element={ <Stacks /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.BLOGS }
          element={ <Blogs /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.USER }
          element={ <UserDescriptionPage /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.ADD_NEW_MEMBER }
          element={ <AddNewMember /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.EDIT_MEMBER }
          element={ <EditMember /> }
        />
        <Route
          path={ ServicePrivateAbsoluteRoutes.NOTIFICATIONS }
          element={ <Notifications /> }
        />
        <Route path={ ServicePrivateAbsoluteRoutes.CHATS } element={ <Chats /> } />
        <Route path={ ServicePrivateAbsoluteRoutes.CLIENTS } element={ <Clients /> } />
        <Route path={ ServicePrivateAbsoluteRoutes.CLIENT } element={ <ClientDetailsPage /> } />
        <Route path={ ServicePrivateAbsoluteRoutes.FREELANCERS } element={ <Freelancers /> } />
        <Route path={ ServicePrivateAbsoluteRoutes.FREELANCER } element={ <FreelancerDetailsPage /> }>
          <Route index element={ <FreelancerDetails /> } />
          <Route path={ ServicePrivateAbsoluteRoutes.JOB_CHAT } element={ <UnmatchedFreelancerChat /> } />
        </Route>
        <Route path={ ServicePrivateAbsoluteRoutes.JOB_DETAILS } element={ <JobDetails /> }>
          <Route index element={ <WithRedirectToJobDetails /> } />
          <Route path={ ServicePrivateAbsoluteRoutes.JOB_DETAILS_BACKUP } element={ <JobDetailsTemplate /> } />
          <Route path={ ServicePrivateAbsoluteRoutes.JOB_MATCHES } element={ <ServiceProviderMatching /> } />
          <Route path={ ServicePrivateAbsoluteRoutes.JOB_CHAT } element={ <ClientChat /> } />
          <Route path={ ServicePrivateAbsoluteRoutes.FREELANCER_JOB_CHAT } element={ <MatchedFreelancerChat /> } />
        </Route>
      </Route>
      <Route
        path='/user-description/:userRole/:hash'
        element={ (
          <WithAxiosResponseInterceptor>
            <DynamicUserDescriptionWrapper />
          </WithAxiosResponseInterceptor>
        ) }
      />
      <Route path='/policy' element={ <Policy /> } />
      <Route path='/terms' element={ <Terms /> } />
      <Route path='/health' element={ <div>health</div> } />
      <Route path='/' element={ <Landing /> } />
    </Routes>
  </Suspense>
);

export default AppRouter;
