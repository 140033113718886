import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/api';
import { useAlertModal } from 'src/context/alertMessageContext';

interface Props {
  children: ReactNode;
}
const WithAxiosResponseInterceptor: FC<Props> = ({ children }): ReactElement => {
  const { addAlertMessages } = useAlertModal();

  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        if (error.response && error.response.status === 403) {
          const { response } = error;
          if (response.data.detail === 'Given token not valid for any token type') {
            addAlertMessages(["Time's up, please close the form."]);
          } else if (response.data.detail === 'Authentication credentials were not provided.') {
            addAlertMessages(['You are not authorized for this action.']);
          }
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [addAlertMessages]);
  return <div>{ children }</div>;
};
export default WithAxiosResponseInterceptor;
