import {
  createContext, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState,
} from 'react';

export interface FiltersContextInterface {
      accepted: boolean;
      setAccepted: Dispatch<SetStateAction<boolean>>;
      rejected: boolean;
      setRejected: Dispatch<SetStateAction<boolean>>;
      pending: boolean;
      setPending: Dispatch<SetStateAction<boolean>>;
      resetFilters: () => void;
    }

export const FiltersContext = createContext<FiltersContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const FiltersContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [pending, setPending] = useState(false);

  const resetFilters = useCallback(() => {
    setAccepted(false);
    setRejected(false);
    setPending(false);
  }, []);

  const contextValues = useMemo(() => ({
    accepted,
    setAccepted,
    rejected,
    setRejected,
    pending,
    setPending,
    resetFilters,
  }), [accepted, rejected, pending, resetFilters]);

  return (
    <FiltersContext.Provider value={ contextValues }>
      { children }
    </FiltersContext.Provider>
  );
};

export default FiltersContextProvider;

export const useFilters = () => {
  const value = useContext(FiltersContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
