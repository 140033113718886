import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { verifyToken } from 'src/api';
import { LoginResponse } from 'src/models/general';

export const useVerifyToken = () => {
  const {
    mutate, error,
  } = useMutation<LoginResponse, AxiosError, string>(
    'verify-token',
    (token) => verifyToken(token),
  );

  return {
    verifyToken: mutate, error,
  };
};

export default useVerifyToken;
