import {
  createContext, FC, ReactElement, ReactNode, useCallback, useContext, useMemo, useState,
} from 'react';
import { DEFAULT_MODAL_TIMEOUT } from 'src/constants';
import { FEEDBACK_MODAL_ICONS } from 'src/enums';

export interface FeedbackContextInterface {
  feedbackModal: string;
  icon: string;
  closeTime: number;
  constructModal: ({
    title,
    iconArg,
    closeTime,
  }: ConstructModalArgs) => void;
}

export const FeedbackContext = createContext<FeedbackContextInterface | null>(null);

type ConstructModalArgs = {
  title: string;
  iconArg?: string;
  closeTime?: number;
}

interface Props {
  children: ReactNode;
}

const FeedbackContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [feedbackModal, setFeedbackModal] = useState('');
  const [icon, setIcon] = useState<string>(FEEDBACK_MODAL_ICONS.DEFAULT);
  const [modalCloseTime, setModalCloseTime] = useState<number>(DEFAULT_MODAL_TIMEOUT);

  const constructModal = useCallback(({
    title,
    iconArg,
    closeTime,
  }: ConstructModalArgs) => {
    setFeedbackModal(title);
    if (iconArg) {
      setIcon(iconArg);
    }
    if (closeTime) {
      setModalCloseTime(closeTime);
    }
  }, []);

  const contextValues = useMemo(() => ({
    feedbackModal,
    icon,
    closeTime: modalCloseTime,
    constructModal,
  }), [feedbackModal, icon, modalCloseTime, constructModal]);

  return (
    <FeedbackContext.Provider value={ contextValues }>
      { children }
    </FeedbackContext.Provider>
  );
};

export default FeedbackContextProvider;

export const useFeedbackModal = () => {
  const value = useContext(FeedbackContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
