import { Navigate, useLocation } from 'react-router-dom';

function withDynamicRedirect(route: string) {
  return () => {
    const { pathname } = useLocation();
    return <Navigate to={ `${pathname}/${route}` } />;
  };
}

export default withDynamicRedirect;
