import { useQuery } from 'react-query';
import { fetchEducationLevel } from 'src/api';
import { QUERY_KEYS } from 'src/enums';

export const useEducationalLevels = () => {
  const { data } = useQuery(QUERY_KEYS.EDUCATION_LEVELS, fetchEducationLevel, {
    staleTime: Infinity,
  });

  const getEducationTitle = (levelId: number) => data?.find(({ id }) => id === levelId)?.title || '';

  return {
    educationLevels: data, getEducationTitle,
  };
};

export default useEducationalLevels;
