import { FC, useEffect } from 'react';
import AppRouter from 'src/routes/appRouter';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import WithErrorAxios from './hoc/withErrorAxios';
import FiltersContextProvider from './context/filtersContext';
import { DefaultTheme, PageWrapper } from './themes';
import JobProvider from './context/jobContext';
import { useAuth } from './context/authContext';
import { decodeToken } from './utils';
import FeedbackContextProvider from './context/modalContext';
import FeedbackModal from './components/UI/molecules/feedbackModal';
import AlertContextProvider from './context/alertMessageContext';
import AlertMessage from './components/UI/molecules/alert';
import CookieCard from './components/UI/molecules/cookieCard';
import LocalesContextProvider from './context/localeContext';
import ScrollToTop from './helpers/scrollToTop';
import FavoriteTalentProvider from './context/favoriteTalentContext';

const App: FC = () => {
  const {
    access, setRole, role, setLocalTokens,
  } = useAuth();

  useEffect(() => {
    if (access && !role) {
      const { role: newRole } = decodeToken(access);
      if (!newRole) {
        setLocalTokens('', '');
      } else {
        setRole(newRole);
      }
    }
  }, [access, setRole, role, setLocalTokens]);

  return (
    <PageWrapper theme={ DefaultTheme }>
      <ScrollToTop />
      <LocalesContextProvider>
        <AlertContextProvider>
          <LocalizationProvider dateAdapter={ AdapterMoment }>
            <WithErrorAxios>
              <AlertMessage />
              <FeedbackContextProvider>
                <FeedbackModal />
                <JobProvider>
                  <FiltersContextProvider>
                    <FavoriteTalentProvider>
                      <AppRouter />
                    </FavoriteTalentProvider>
                    <CookieCard />
                  </FiltersContextProvider>
                </JobProvider>
              </FeedbackContextProvider>
            </WithErrorAxios>
          </LocalizationProvider>
        </AlertContextProvider>
      </LocalesContextProvider>
    </PageWrapper>
  );
};
export default App;
