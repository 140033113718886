import { Grid, LinearProgress } from '@mui/material';

const CircularLoading = () => (
  <Grid
    container
  >
    <Grid item xs={ 12 }>
      <LinearProgress />
    </Grid>
  </Grid>
);

export default CircularLoading;
