/* eslint-disable filenames/match-exported */
import overrides from 'src/themes/overrides';
import palette from 'src/themes/palette';
import { createTheme } from '@mui/material';
import theme from 'src/config/config';


const breakPoints = {
  xs: 0,
  sm: 991,
  md: 1920,
  // normal: 1201,
  // big: 1301,
  // lg: 1501,
  // xl: 1921,
};

const headerHeight = 100;

export const layout = {
  headerHeight,
  sidebarWidth: 230,
  sidebarClosedWidth: 50,
};

const mainTheme = createTheme({
  breakpoints: {
    values: breakPoints,
  },
  layout,
  mixins: {
    toolbar: {
      minHeight: headerHeight,
      maxHeight: headerHeight,
    },
  },
  // @ts-ignore
  components: overrides,
  palette,
  // props: {
  //   MuiButtonBase: {
  //     disableRipple: true,
  //   },
  //   MuiLink: {
  //     underline: 'none',
  //   },
  //   MuiSwitch: {
  //     color: 'primary',
  //   },
  // },
  typography: {
    fontFamily: ['"Roboto"'].join(','),
    // fontSizePX-lineHeightPX
    // 45-56
    h1: {
      color: '#23233c',
      fontSize: '2.8125rem',
      lineHeight: '3.5rem',
      letterSpacing: 0,
    },
    // 40-58-#343A40
    h2: {
      color: palette.text.secondary,
      fontSize: '1.125rem',
      letterSpacing: 0,
      fontWeight: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.75rem',
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
        lineHeight: 1.8,
      },
    },
    // 32-45-#0D0D0D
    h3: {
      color: palette.text.primary,
      fontWeight: 500,
      letterSpacing: 0,
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.25rem',
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: 1.8,
      },
    },
    // 32-45- #2C2E2F
    h4: {
      color: palette.info.contrastText,
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      letterSpacing: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
        lineHeight: '2.8125rem',
      },
    },
    // 24-33-a4a4a4
    h5: {
      color: palette.text.secondary,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '1.6875rem',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
        lineHeight: 1.8,
      },
    },
    // 24-33-#0D0D0D
    h6: {
      color: palette.text.secondary,
      fontSize: '1.5rem',
      lineHeight: '2.0625rem',
      letterSpacing: 0,
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    // 20-28-#2C2E2F
    notificationsBody: {
      color: palette.info.contrastText,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      letterSpacing: 0,
      display: 'block',
      [theme.breakpoints.only('xs')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '1.25rem',
      },
    },
    // 18-25-#343A40
    candidateTitle: {
      color: palette.text.secondary,
      fontSize: '1.125rem',
      lineHeight: '1.5625rem',
      letterSpacing: 0,
    },
    // 16-23-#101113
    body1: {
      color: palette.info.main,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
    },
    // 16-23-#2C2E2F
    body2: {
      color: palette.info.contrastText,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
    },
    // 14-21-#2c2e2f
    subtitle1: {
      color: palette.info.contrastText,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.3125rem',
      letterSpacing: 0,
      [theme.breakpoints.only('sm')]: {
        fontSize: '0.75rem',
      },
    },
    // 16-23-#9499A1
    subtitle2: {
      color: palette.info.light,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
    },
    // 14-20-#101113
    caption: {
      color: palette.info.main,
      fontSize: '0.875em',
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
    // 18-25-#343a40
    descriptionBodyLarge: {
      color: palette.text.secondary,
      fontSize: '1.125rem',
      lineHeight: '1.5625rem',
      letterSpacing: 0,
    },
    // 16-23-#659296
    descriptionBody: {
      color: palette.info.dark,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
    },
    // 16-23-#343A40
    descriptionTitle: {
      color: palette.text.secondary,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
      display: 'block',
      [theme.breakpoints.only('sm')]: {
        fontSize: '0.875rem',
      },
    },
    // 18-25-#101113
    descriptionTitleLarge: {
      color: palette.info.main,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5625rem',
      letterSpacing: 0,
    },
    // 16-23-#a4a4a4
    descriptionTitleSmall: {
      color: palette.text.disabled,
      fontSize: '1rem',
      lineHeight: '1.4375rem',
      letterSpacing: 0,
      [theme.breakpoints.only('sm')]: {
        fontSize: '0.875rem',
      },
    },
    descriptionBoxLabel: {
      lineHeight: '1.56rem',
      color: palette.text.disabled,
      textTransform: 'capitalize',
    },
    descriptionBoxText: {
      color: palette.info.main,
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '1.68rem',
      textTransform: 'capitalize',
    },
    navLink: {
      color: palette.text.secondary,
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      textTransform: 'capitalize',
    },
  },
});

export default mainTheme;
