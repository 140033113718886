import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchProfessions } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { ProfesionModel } from 'src/models/general';

export const useProfessions = () => {
  const {
    data, isLoading,
  } = useQuery<ProfesionModel[]>(
    QUERY_KEYS.PROFESSIONS,
    fetchProfessions,
    {
      staleTime: Infinity,
    },
  );

  const getProfessionTitle = useMemo(
    () => (professionId: number | string) => {
      if (data) {
        return data?.find(({ id }) => id === +professionId)?.title || '';
      }
      return '';
    },
    [data],
  );

  return {
    professions: data,
    isLoading,
    getProfessionTitle,
  };
};

export default useProfessions;
