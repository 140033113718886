import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import freelanceSkillwillBlack from 'src/assets/logos/freelanceSkillwillBlack.png';
import StyledLogo from 'src/components/UI/atoms/styledLogo';


type Props = {
    children: React.ReactNode;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 1,
  paddingX: 0,
  paddingBottom: 0,
  '&.MuiToolbar-root': {
    [theme.breakpoints.up('sm')]: {
      paddingInline: 0,
    },
  },
  [theme.breakpoints.up('sm')]: {
    width: '83%',
  },
}));

const UserDescriptionWrapper = ({ children }: Props) => (
  <div>
    <Box
      sx={ {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
      } }
    >
      <StyledToolbar>
        <StyledLogo>
          <img id='freelanceSkillwillBlack' src={ freelanceSkillwillBlack } alt='freelanceskillwilllogo' />
        </StyledLogo>
      </StyledToolbar>
    </Box>
    { children }
  </div>
);

export default UserDescriptionWrapper;
