import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledLogo = styled(Box)(({ theme }) => ({
  maxWidth: 267,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    objectFit: 'cover',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 260,
  },
}));

export default StyledLogo;
