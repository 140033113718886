import { useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import Button from 'src/components/UI/atoms/landingButton';
import { ReactComponent as CookieIcon } from 'src/assets/cookieIcon/cookie.svg';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { APIRoutesEnum, LocalStorageEnum } from 'src/enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultTheme from 'src/styledComponentTheme/theme';
import { ThemeProvider } from 'styled-components';


const StyledCookieWrapper = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  right: 40,
  bottom: 40,
  width: 352,
  height: 236,
  background: theme.palette.primary.light,
  boxShadow: '5px 5px 15px #0000001A',
  borderRadius: 16,
  zIndex: 10,
  [theme.breakpoints.down('sm')]: {
    width: 300,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4.125),
  right: theme.spacing(5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.contrastText,
}));

const CookieCard = () => {
  const { t: translate } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const navigate = useNavigate();

  const isCookieAccepted = localStorage.getItem(LocalStorageEnum.COOKIE_ACCEPTED);

  useEffect(() => {
    if (isCookieAccepted) {
      setIsVisible(false);
    }
  }, [isCookieAccepted]);


  const handleHideCookie = () => {
    setIsVisible(false);
    localStorage.setItem(LocalStorageEnum.COOKIE_ACCEPTED, 'true');
  };

  const handleCloseCookie = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ThemeProvider theme={ DefaultTheme }>
      <StyledCookieWrapper
        paddingTop={ 4 }
        paddingBottom={ 5.5 }
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={ 10 } marginBottom={ 1 } textAlign='center'>
            <CookieIcon />
            <StyledCloseButton
              size='small'
              onClick={ handleCloseCookie }
              sx={ {
                marginRight: {
                  xs: -3,
                  sm: -3,
                  md: 0,
                },
              } }
            >
              <CloseIcon />
            </StyledCloseButton>
          </Grid>
          <Grid item xs={ 10 } textAlign='center'>
            <Typography>
              { translate('Modals.Message.cookieTitle') }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 10 }
            textAlign='center'
            onClick={ () => navigate(APIRoutesEnum.TERMS) }
            sx={ {
              cursor: 'pointer',
            } }
          >
            <Typography fontWeight={ 700 }>
              { translate('Modals.Message.cookieSubtitle') }
            </Typography>
          </Grid>
          <Grid item xs={ 12 } marginTop={ 2 } textAlign='center'>
            <Button
              primary
              onClick={ handleHideCookie }
            >
              { translate('Buttons.iAgree') }
            </Button>
          </Grid>
        </Grid>
      </StyledCookieWrapper>
    </ThemeProvider>
  );
};

export default CookieCard;
