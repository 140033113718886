import {
  createContext, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState,
} from 'react';
import { JobsPostingPayload } from 'src/models/formData';
import { SkillModel } from 'src/models/general';
import { useSkills } from 'src/queryHooks';

// type JobModel = JobModel & { new_skills: { title: string; }[]; }

export type JobProviderType = {
    job: JobsPostingPayload | null;
    newSkills: SkillModel[];
    setJob: Dispatch<SetStateAction<JobsPostingPayload | null>>;
    addNewSkill: (title: string) => SkillModel | null;
    removeSkillId: (skillId: number) => void;
}

export const ProviderContext = createContext<JobProviderType | null>(null);

interface Props {
  children: ReactNode;
}

const JobProvider: FC<Props> = ({ children }): ReactElement => {
  const [job, setJob] = useState<JobsPostingPayload | null>(null);
  const [newSkills, setNewSkills] = useState<SkillModel[]>([]);

  const { skills } = useSkills();

  const checkIfSkillExists = useCallback((skillToAdd: string): boolean => (
    !!newSkills.find(({ title }) => title === skillToAdd)
  ), [newSkills]);

  const addNewSkill = useCallback((title: string) => {
    if (checkIfSkillExists(title)) {
      return null;
    }

    const newSkillId = skills?.length ? skills.length + 1 : 1;
    const newSkill = {
      id: newSkillId, title,
    };
    setNewSkills((prev) => [...prev, newSkill]);

    return newSkill;
  }, [checkIfSkillExists, skills?.length]);

  const removeSkillId = useCallback((skillId: number) => {
    setNewSkills((prev) => [...prev.filter((sk) => sk.id !== skillId)]);
  }, []);

  const contextValues = useMemo(() => ({
    job,
    newSkills,
    setJob,
    addNewSkill,
    removeSkillId,
  }), [job, newSkills, addNewSkill, removeSkillId]);

  return (
    <ProviderContext.Provider value={ contextValues }>
      { children }
    </ProviderContext.Provider>
  );
};

export default JobProvider;

export const useJobProvider = () => {
  const value = useContext(ProviderContext);

  if (!value) {
    throw new Error('Context Provider is not defined');
  }
  return value;
};
