import { useMutation, useQueryClient } from 'react-query';
import { createJobRequest, updateJobRequest } from 'src/api';
import { CreateJobRequest, JobModel } from 'src/models/general';

type useQreateJobProps = {
    onSuccess: (newJob: JobModel) => void;
}

export const useCreateJob = ({ onSuccess }: useQreateJobProps) => {
  const queryClient = useQueryClient();
  const {
    mutate, isError, isLoading,
  } = useMutation<JobModel, Error, CreateJobRequest>('create-job', (props) => createJobRequest(props), {
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries(['jobs']);
    },
  });

  return {
    createJob: mutate, isError, isLoading,
  };
};

export const useUpdateJob = ({ onSuccess }: useQreateJobProps) => {
  const {
    mutate, isError, isLoading,
  } = useMutation<JobModel, Error, { job: CreateJobRequest; jobId: string; }>(
    'update-job',
    ({
      job, jobId,
    }) => updateJobRequest(job, jobId),
    {
      onSuccess,
    },
  );

  return {
    updateJob: mutate, isError, isLoading,
  };
};

export default useCreateJob;
