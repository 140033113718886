import { ComponentsOverrides } from '@mui/material/styles/overrides';

const surfaces: ComponentsOverrides = {
  MuiPaper: {
    root: {
      boxShadow: '0px 0px 10px #0000000D',
    },
    rounded: {
      borderRadius: '1rem',
    },
    elevation1: {
      boxShadow: '5px 0 25px rgba(0,0,0,0.15)',
      padding: '2.5rem',
      backgroundColor: 'red',
    },
    elevation5: {
      '&.select-input': {
        boxShadow: '0 3px 5px -1px rgba(0,0,0,0.1), 0 5px 8px 0 rgba(0,0,0,0.0), 0 1px 14px 0 rgba(0,0,0,0.1)',
      },
    },
    elevation8: {
      boxShadow: '0px 0px 10px #000',
    },
  },
};

export default surfaces;
