export const passwordValidation = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;


export const passwordVaildator = {
  digit: /\d/,
  uppercase: /^(?=.*[a-z])[a-z0-9]+$/,
  symbol: /[@$!%*?&]/,
};
// eslint-disable-next-line unicorn/no-unsafe-regex,max-len
export const emailValidation = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const forLintError = 'ss';
