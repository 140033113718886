import styled, { css } from 'styled-components';

export type ButtonProps = {
    primary?: boolean;
    secondary?: Boolean;
    wide?: boolean;
    extraWide?: boolean;
    paddingY?: number;
    headerButtons?: boolean;
    customizedWidth?: boolean;
    mobileButton?: boolean;
    headerSignIn?: boolean;
    headerListButton?: boolean;
    headerListMobileButton?: boolean;
}

const Button = styled.button<ButtonProps>`
    background: transparent;
    border-radius: 4px;
    border: none;
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 18px;
    font-family: 'Roboto';
    cursor: pointer;

    &:first-letter {
        text-transform: uppercase;
    }

    ${(props) => (props.paddingY ? css`
        padding: ${props.paddingY}px 41px;
    ` : css`
        padding: 12px 35px;
    `)}

    ${(props) => props.primary && css`
        background: ${props.theme.palette.purple.main};
        color: ${props.theme.palette.common.white};
    `}

    ${(props) => props.secondary && css`
        background: ${props.theme.palette.common.white};
        color: ${props.theme.palette.common.white};
        border: 2px solid ${props.theme.palette.common.white};
    `}

    ${(props) => props.headerSignIn && css`
        background: ${props.theme.palette.common.white};
        color: ${props.theme.palette.common.black};
        font-weight: 400;
    `}

    ${(props) => props.wide && css`
        max-width: fit-content;
        width: 100%;
    `}

    ${(props) => props.extraWide && css`
        max-width: 536px;
        width: 100%;
    `}
    
    ${(props) => props.headerButtons && css`
        min-width: 176px;
        padding: 6px 35px;
    `}

    ${(props) => props.customizedWidth && css`
        padding: 6px 15px;
        min-width: 109px;
    `}

    ${(props) => props.mobileButton && css`
        border: 1px solid ${({ theme }) => theme.palette.purple.main};
        color: ${({ theme }) => theme.palette.purple.dark};
    `}

    ${(props) => props.headerListButton && css`
        color: ${({ theme }) => theme.palette.common.white};
        padding: 0px;
    `}

    ${(props) => props.headerListMobileButton && css`
        color: ${({ theme }) => theme.palette.common.black};
        padding: 0px;
        font-weight: 700;
        font-size: 24px;
    `}

    @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    ${(props) =>
    props.headerButtons
      && css`
        min-width: fit-content;
      `}
    }
`;

export default Button;
