import palette from 'src/themes/palette';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const navigation: ComponentsOverrides = {
  MuiTab: {
    root: {
      fontWeight: 400,
      textTransform: 'none',
      backgroundColor: 'red',
    },
    textColorPrimary: {
      '&$selected': {
        color: palette.text.primary,
      },
    },
  },
};

export default navigation;
