import { FC, ReactNode } from 'react';
import { CssBaseline, Theme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

type PageWrapperProps = {
  children: ReactNode;
  theme: Theme;
}

const PageWrapper: FC<PageWrapperProps> = ({
  children, theme,
}) => (
  <MuiThemeProvider theme={ theme }>
    <CssBaseline />
    { children }
  </MuiThemeProvider>
);

PageWrapper.defaultProps = {};

export default PageWrapper;
