import inputs from './inputs';
import feedback from './feedback';
import navigation from './navigation';
import dataDisplay from './dataDisplay';
import surfaces from './surfaces';

export default {
  ...inputs,
  ...feedback,
  ...navigation,
  ...dataDisplay,
  ...surfaces,
};
