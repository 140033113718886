import { createTheme } from '@mui/material';

export const breakPoints = {
  xs: 0,
  sm: 991,
  md: 1920,
  // normal: 1201,
  // big: 1301,
  // lg: 1501,
  // xl: 1921,
};

const theme = createTheme({
  breakpoints: {
    values: breakPoints,
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'inherit',
          },
          style: {
            color: '#a4a4a4',
            fontSize: '1rem',
            lineHeight: '1.5625rem',
            letterSpacing: 0,
          },
        },
      ],
    },
  },
});

export default theme;
