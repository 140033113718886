import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { addNewSkills, fetchSkills } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { SkillModel } from 'src/models/formData';
import { CreateSkillsSuccess } from 'src/models/general';

export const useSkills = () => {
  const {
    data, isLoading,
  } = useQuery<SkillModel[]>(QUERY_KEYS.SKILLS, fetchSkills, {
    refetchOnMount: true,
  });

  function getSkillLabel(skillId: number) {
    return data?.find(({ id }) => id === skillId)?.title || '';
  }

  const getSkillsTitles = useCallback((skillIds: number[]) => {
    if (!data) {
      return '';
    }
    const skilTitles: string[] = [];
    skillIds?.forEach((skId) => {
      const foundSkill = data.find(({ id }) => id === skId);
      if (foundSkill) {
        skilTitles.push(foundSkill.title);
      }
    });
    return skilTitles.length ? skilTitles.join(', ') : '';
  }, [data]);

  const getSkills = useCallback((skillIds: number[]) => data?.filter((skill) => skillIds.includes(skill.id)) || [], [data]);

  const { mutate: createNewSkills }
  = useMutation<CreateSkillsSuccess[], Error, { title: string; }[]>(
    'create-new-skills',
    (props) => addNewSkills(props),
  );

  return {
    skills: data, isLoading, getSkillLabel, getSkillsTitles, createNewSkills, getSkills,
  };
};

export default useSkills;
