import { DefaultTheme as StyledDefaultTheme } from 'styled-components';

const DefaultTheme: StyledDefaultTheme = {
  borderRadius: '4px',
  palette: {
    common: {
      black: '#2C2E2F',
      white: '#ffffff',
    },
    primary: {
      main: '#726a95',
      contrastText: '#ffffff',
      light: 'rgba(238, 234, 229, 0.2)',
      dark: '#191919',
    },
    secondary: {
      main: '#709fb0',
      contrastText: '#82A3AA',
      light: '#F9F9F9',
    },
    pink: {
      main: '#DD6E64',
      contrastText: '#ffffff',
      dark: '#DD6E64',
    },
    green: {
      main: '#1DD072',
      dark: '#21DB79',
    },
    purple: {
      main: '#AB7DF4',
      dark: '#6941C6',
    },
    gray: {
      main: '#65929B',
      dark: '#343A40',
    },
    red: {
      dark: '#A93B31',
    },
    service: {
      main: '#2D4C54',
      contrastText: '#251A2E',
      light: '#F9F9F9',
    },
    contact: {
      main: '#101113',
      contrastText: '#A4A4A4',
    },
  },
  breakPoints: {
    xs: 0,
    sm: 500,
    sm1: 600,
    md: 800,
    md1: 1000,
    lg: 1366,
    lg1: 1580,
    xl: 1920,
  },
};


export default DefaultTheme;
