import { ComponentsOverrides } from '@mui/material/styles/overrides';
import palette from 'src/themes/palette';
import { Components } from '@mui/material/styles/components';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import theme from 'src/config/config';
// import theme from 'src/config/config';

// const inputHeight = 50;
// const textFieldInputBase = {
//   padding: '0 12px',
//   height: inputHeight,
//   fontSize: 15,
//   fontWeight: 400,
// };

const errorObj = {
  border: `1px solid ${palette.error.main}`,
  borderRadius: 8,
  overflow: 'hidden',
  '.MuiFilledInput-input': {
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
    },
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: 16,
  },
};

const successObj = {
  border: `1px solid ${palette.icon.primary}`,
  borderRadius: 8,
  overflow: 'hidden',
  '.MuiFilledInput-input': {
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
    },
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: 16,
  },
};

const inputs: Components<ComponentsOverrides> = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: {
          variant: 'contained',
        },
        style: {
          border: 0,
          borderRadius: 4,
          background: palette.skillWillColors.main,
          color: palette.primary.light,
          fontWeight: 400,
          boxShadow: 'none',
          '&:hover': {
            background: palette.skillWillColors.dark, // a93b31
          },
        },
      },
      {
        props: {
          variant: 'containedSecondary',
        },
        style: {
          fontSize: 16,
          lineHeight: 3.25,
          textTransform: 'capitalize',
          border: 0,
          borderRadius: 4,
          background: palette.skillWillColors.main, // #21DB79
          color: palette.primary.light, // #191919
          '&:hover': {
            background: palette.skillWillColors.dark, // #27C974
          },
        },
      },
      {
        props: {
          variant: 'outlined',
        },
        style: {
          textTransform: 'capitalize',
          lineHeight: 3.25,
          border: `1px solid ${palette.skillWillColors.main}`, // DD6E64
          borderRadius: 4,
          background: palette.primary.light, // #FFFFFF
          color: palette.skillWillColors.main, // DD6E64
          '&:hover': {
            background: palette.skillWillColors.main,
            color: palette.primary.light,
          },
          [theme.breakpoints.up('sm')]: {
            paddingInline: 13,
            height: 45,
          },
          [theme.breakpoints.up('md')]: {
            paddingInline: 13,
            height: 56,
          },
        },
      },
      {
        props: {
          variant: 'outlinedSecondary',
        },
        style: {
          textTransform: 'capitalize',
          border: '1px solid #2C2E2F', // `1px solid theme.pallete.secondary`
          borderRadius: 8,
          background: 'white', // theme.pallete.secondary
          color: '#2C2E2F', // theme.pallete.secondary
          [theme.breakpoints.up('sm')]: {
            paddingInline: 24,
            height: 45,
          },
          [theme.breakpoints.up('md')]: {
            paddingInline: 49,
            height: 56,
          },
        },
      },
      {
        props: {
          variant: 'text',
        },
        style: {
          padding: '0.75rem 0 0.8125rem 0',
          borderRadius: '0.75rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#65929B',
        },
      },
      {
        props: {
          variant: 'outlinedGreen',
        },
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: palette.warning.contrastText,
          border: `1px solid ${palette.warning.contrastText}`,
        },
      },
      {
        props: {
          variant: 'outlinedRed',
        },
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: palette.icon.disabled,
          border: `1px solid ${palette.icon.disabled}`,
        },
      },
      {
        props: {
          size: 'small',
        },
        style: {
          height: '50px',
          lineHeight: 3.25,
        },
      },
      {
        props: {
          size: 'medium',
        },
        style: {
          [theme.breakpoints.up('xs')]: {
            minWidth: 236,
            height: 56,
            fontSize: 18,
            lineHeight: '26px',
          },
          [theme.breakpoints.only('sm')]: {
            minWidth: 144,
            height: 45,
            paddingInline: 32,
            fontSize: 16,
          },
          [theme.breakpoints.up('md')]: {
            minWidth: 206,
            height: 56,
            fontSize: 18,
          },
        },
      },
      {
        props: {
          size: 'large',
        },
        style: {
          [theme.breakpoints.up('xs')]: {
            minWidth: 236,
            height: 56,
            fontSize: 18,
            lineHeight: '26px',
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: 64,
            height: 48,
            paddingInline: 32,
            fontSize: 16,
            lineHeight: '26px',
          },
          [theme.breakpoints.up('md')]: {
            minWidth: 304,
            height: 56,
            fontSize: 18,
            lineHeight: '26px',
          },
        },
      },
      {
        props: {
          size: 'tiny',
        },
        style: {
          fontSize: '0.875rem',
          lineHeight: '1.3125',
          paddingInline: 16,
        },
      },
      {
        props: {
          size: 'auto',
        },
        style: {
          minWidth: 'auto',
          height: 'auto',
        },
      },
      {
        props: {
          size: 'full',
        },
        style: {
          minWidth: 'auto',
          [theme.breakpoints.up('xs')]: {
            height: 56,
            fontSize: 18,
            lineHeight: '26px',
          },
          [theme.breakpoints.up('sm')]: {
            height: 45,
            paddingInline: 32,
            fontSize: 16,
            lineHeight: '26px',
          },
          [theme.breakpoints.up('md')]: {
            height: 56,
            fontSize: 18,
            lineHeight: '26px',
          },
        },
      },
      {
        props: {
          variant: 'activeButton',
        },
        style: {
          height: 'auto',
          padding: '6px 8px',
          border: 0,
          whiteSpace: 'nowrap',
          background: palette.primary.contrastText,
          color: palette.info.main,
          fontSize: 14,
          lineHeight: '1.3125rem',
          borderRadius: theme.spacing(1),
          ':first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
      {
        props: {
          variant: 'unstyled',
        },
        style: {
          padding: 0,
          border: 0,
          height: 'auto',
          background: 'transparent',
          textTransform: 'none',
          fontWeight: 400,
          ':hover': {
            background: 'transparent',
          },
          '&.MuiButton-root': {
            padding: 0,
            height: 'auto',
            minWidth: 'auto',
          },
        },
      },
      {
        props: {
          variant: 'uploadButton',
        },
        style: {
          padding: 0,
          border: 0,
          minWidth: 'auto',
          background: 'transparent',
          textTransform: 'none',
          height: 'auto',
          ':hover': {
            background: 'transparent',
          },
          '&.MuiButton-root': {
            padding: 0,
          },
          [theme.breakpoints.only('sm')]: {
            fontSize: 14,
          },
        },
      },
      {
        props: {
          size: 'inputButton',
        },
        style: {
          fontWeight: 400,
          lineHeight: '1.3125',
          [theme.breakpoints.up('sm')]: {
            borderRadius: '4px 8px 8px 4px',
            height: '100%',
            paddingInline: 30,
          },
          [theme.breakpoints.up('md')]: {
            borderRadius: 8,
            height: '100%',
            paddingInline: 44,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        backgroundColor: palette.primary.main,
        borderRadius: '0.5rem',
      },
    },
    variants: [
      {
        props: {
          size: 'small',
        },
        style: {
          width: '2rem',
          height: '2rem',
        },
      },
      {
        props: {
          size: 'medium',
        },
        style: {
          width: '3rem',
          height: '3rem',
        },
      },
    ],
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: palette.secondary.dark,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {},
    },
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        display: 'inline-block',
        background: 'white',
        borderRadius: '1rem 1rem 0 0',
        padding: 0,
        margin: '-9px 0',
        '.MuiTab-root': {
          fontSize: '1.25rem',
          letterSpacing: 0,
          padding: '0.625rem 2.25rem',
          textTransform: 'capitalize',
          color: '#A4A4A4',
          [theme.breakpoints.only('sm')]: {
            fontSize: 18,
            lineHeight: '26px',
          },
        },
        '.Mui-selected': {
          color: '#343A40!important',
        },
      },

      indicator: {
        backgroundColor: palette.skillWillColors.main,
        height: '6px',
        top: '45px',
        borderRadius: '1rem',
        '&.client-tabs': {
          backgroundColor: palette.primary.dark,
          top: '42px',
          height: 8,
        },
      },
    },
  },

  MuiLinearProgress: {
    defaultProps: {
      style: {
        background: palette.skillWillColors.main,
      },
    },
  },

  MuiFilledInput: {
    // defaultProps: {
    //   endAdornment: {
    //     maxWidth: '50px',
    //     background: 'white',
    //   },
    // },
    styleOverrides: {
      // inputAdornedEnd: {
      // },
      // inputAdornedStart: {
      //   background: 'red',
      //   minWidth: '100%',
      // },
      root: {
        backgroundColor: palette.primary.main, // #F9F9F9
        borderRadius: 8,
        height: 56,
        paddingRight: 0,
        color: palette.info.main, // #101113
        '&:hover': {
          backgroundColor: palette.primary.main, // #F9F9F9
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: palette.primary.main, // #F9F9F9
          },
        },
        '&.Mui-focused': {
          backgroundColor: palette.primary.main, // #F9F9F9
        },
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          border: 'none',
        },
        '& .MuiSelect-icon': {
          marginRight: '4px',
        },
        '& .MuiButtonBase-root': {
          marginRight: '16px',
        },
        '.MuiSelect-select': {
          padding: '10px 16px',
          // color: palette.info.light,
          ':focus': {
            backgroundColor: 'transparent',
          },
        },
        '& input': {
          padding: '10px 16px',
        },
        '&.Mui-error': errorObj,
        [theme.breakpoints.up('sm')]: {
          height: 50,
        },
        [theme.breakpoints.up('md')]: {
          height: 64,
          borderRadius: 16,
        },
      },
      multiline: {
        padding: '25px 16px 8px',
        minHeight: '8rem',
        alignItems: 'flex-start',
        '& textarea': {
          height: '100%',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          border: 'none',
          borderRadius: '0px',
          marginLeft: 0,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      SelectProps: {
        IconComponent: KeyboardArrowDownRounded,
        disableUnderline: true,
      },
    },
    styleOverrides: {
      root: {
        '& input': {
          padding: '10px 16px !important',
          '&[type=number]': {
            MozAppearance: 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    variants: [
      {
        props: {
          variant: 'filled',
        },
        style: {
          '& .Mui-error': errorObj,
          color: 'blue',
        },
      },
      {
        props: {
          variant: 'standard',
        },
        style: {
          height: 56,
          borderRadius: 8,
          background: palette.primary.light, // #FFFFFF
          '.MuiInput-root': {
            height: '100%',
            '&::before': {
              border: 'none',
            },
            '&::after': {
              border: 'none',
            },
            '&:hover:not(.Mui-disabled):before': {
              border: 'none',
            },
          },
          '.MuiSelect-select': {
            padding: '10px 16px',
            // color: '#9499A1',
            ':focus': {
              backgroundColor: palette.primary.light, // #FFFFFF
            },
          },
          '& .MuiSelect-icon': {
            marginRight: '10px',
          },
          '& .MuiButtonBase-root': {
            marginRight: '16px',
          },
          '& input': {
            paddingInline: '8px',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
          },
          '& .Mui-error': errorObj,
          '& .success-border': successObj,
          [theme.breakpoints.up('sm')]: {
            height: 50,
            borderRadius: 8,
          },
          [theme.breakpoints.up('sm')]: {
            height: 44,
          },
          [theme.breakpoints.up('md')]: {
            height: 64,
            borderRadius: 16,
          },
        },
      },
      {
        props: {
          variant: 'filled',
        },
        style: {
          '& .Mui-error': errorObj,
          '& .success-border': successObj,
          '& input': {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #f9f9f9 inset',
            },
          },
        },
      },
      {
        props: {
          variant: 'outlined',
        },
        style: {
          background: palette.primary.light,
          borderRadius: '0.75rem',
        },
      },
      // <<<<<<< HEAD
      //= ======
      // {
      //   props: {
      //     variant: 'outlined',
      //   },
      //   style: {
      //     '.MuiOutlinedInput-root': {
      //       borderRadius: '0.75rem',
      //       backgroundColor: '#FFFFFF',
      //     },
      //     '.Mui-focused': {
      //       border: '1px solid #E8E5E5',
      //     },
      //   },
      // },
      // {
      //   props: {
      //     size: 'small',
      //   },
      //   style: {
      //     '.MuiOutlinedInput-root': {
      //       height: '3rem',
      //       width: '9rem',
      //     },

      //   },
      // },
      // >>>>>>> c0f751231d03c6bd7749b04f954ad21b45eb0d59
    ],
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '0.75rem',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        ':first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: '18px',
        color: palette.text.secondary, // #0D0D0D
        lineHeight: '27px',
        marginBottom: '0.5rem',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
      asterisk: {
        color: palette.mandatory, // #F27CAC
        '&$error': {
          color: palette.mandatory, // #F27CAC
        },
      },
    },
  },

  MuiDivider: {
    styleOverrides: {
      root: {
        borderBottomWidth: '4px',
        borderBottomColor: '#E8E5E5',
        margin: '1.5rem 0',
      },
    },
  },

  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
        '.Mui-error': errorObj,
        '&.placeholder': {
          color: palette.placeholder,
        },
      },
      standard: {
        background: 'white',
        padding: '0 16px',
        borderRadius: 8,
        flexWrap: 'nowrap',
        fontSize: 16,
        ':focus': {
          background: 'white',
        },
        '&.MuiSelect-select': {
          boxSizing: 'border-box',
          paddingRight: '32px',
        },
        [theme.breakpoints.up('xs')]: {
          height: 56,
        },
        [theme.breakpoints.up('sm')]: {
          height: 44,
          fontSize: 14,
          '&.MuiSelect-select': {
            paddingBlock: '10px',
          },
        },
        [theme.breakpoints.up('md')]: {
          height: 64,
          borderRadius: 16,
          fontSize: 16,
          '&.MuiSelect-select': {
            paddingBlock: '20px',
          },
        },
      },
      icon: {
        marginRight: 16,
        transition: 'transform .3s ease-in-out',
      },
    },
    defaultProps: {
      IconComponent: KeyboardArrowDownRounded,
      disableUnderline: true,
      MenuProps: {
        disableAutoFocusItem: true,
        PaperProps: {
          style: {
            background: palette.primary.main,
            padding: 0,
            marginTop: '0.5rem',
            borderRadius: '1rem',
            transform: 'translate(0, 8px)',
            maxHeight: 250,
            boxShadow: '0px 3px 6px #00000029',
          },
        },
      },
      fullWidth: true,
      inputProps: {
        'aria-label': 'Without label',
      },
    },
    variants: [
      {
        props: {
          variant: 'filled',
        },
        style: {
          '& .Mui-error': {
            errorObj,
          },
        },
      },
    ],
  },

  MuiAvatar: {
    styleOverrides: {
      root: {
        '.MuiSvgIcon-root': {
          display: 'none',
        },
        lineHeight: 0,
      },
    },
    // defaultProps: {
    //   imgProps: {
    //     loading: 'lazy',
    //   },
    // },
    variants: [
      {
        props: {
          variant: 'rounded',
        },
        style: {
          border: 0,
          borderRadius: 8,
          '&.MuiAvatar-colorDefault': {
            background: palette.skillWillColors.main,
          },
          lineHeight: 0,
        },
      },
      {
        props: {
          variant: 'roundedDark',
        },
        style: {
          border: 0,
          borderRadius: 8,
          '&.MuiAvatar-colorDefault': {
            background: palette.secondary.dark,
          },
          lineHeight: 0,
        },
      },
      {
        props: {
          variant: 'roundedLight',
        },
        style: {
          borderRadius: 8,
          '&.MuiAvatar-colorDefault': {
            background: palette.text.disabled,
          },
          lineHeight: 0,
        },
      },
      {
        props: {
          variant: 'roundedGreen',
        },
        style: {
          borderRadius: 8,
          '&.MuiAvatar-colorDefault': {
            background: palette.secondary.contrastText,
          },
          lineHeight: 0,
        },
      },
      {
        props: {
          sizes: 'small',
        },
        style: {
          width: 48,
          height: 48,
          lineHeight: 0,
        },
      },
      {
        props: {
          sizes: 'medium',
        },
        style: {
          width: 76,
          height: 76,
          lineHeight: 0,
        },
      },
      {
        props: {
          sizes: 'large',
        },
        style: {
          width: 100,
          height: 100,
          lineHeight: 0,
        },
      },
      {
        props: {
          sizes: 'extraLarge',
        },
        style: {
          width: 161,
          height: 161,
          lineHeight: 0,
          [theme.breakpoints.up('md')]: {
            width: 240,
            height: 240,
          },
        },
      },
    ],
  },

  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: palette.info.main,
        textTransform: 'capitalize',
        // borderRadius: '0.5rem',
        paddingLeft: 16,
        paddingRight: 16,
        ':hover': {
          background: 'inherit',
        },
      },
    },
    variants: [
      {
        props: {
          hidden: true,
        },
        style: {
          display: 'none',
        },
      },
    ],
  },

  MuiAutocomplete: {
    defaultProps: {
      componentsProps: {
        paper: {
          style: {
            overflow: 'hidden',
          },
        },
      },
    },
    styleOverrides: {
      inputRoot: {
        padding: 0,
        width: '100%',
      },
      endAdornment: {
        top: 0,
        '.MuiButtonBase-root': {
          background: 'transparent',
          transition: 'transform .3s ease-in-out',
          marginRight: -8,
          ':hover': {
            background: 'transparent',
          },
          ':focus': {
            marginRight: -8,
          },
        },
        [theme.breakpoints.up('md')]: {
          top: '15%',
        },
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        fontSize: '1.5rem',
        lineHeight: '2.1875rem',
        paddingTop: 0,
        fontWeight: 600,
      },
    },
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(10.5),
        marginBottom: theme.spacing(6),
      },
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        color: palette.success.dark, // #23233C
        boxShadow: 'none',
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '18px',
        fontWeight: 500,
        color: palette.text.secondary, // #343a40
        lineHeight: '27px',
        marginBottom: theme.spacing(2),
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
      asterisk: {
        color: palette.mandatory, // #F27CAC
        '&$error': {
          color: palette.mandatory, // #F27CAC
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        minWidth: 160,
        transform: 'translateY(10px) !important',
        borderRadius: 16,
        padding: '16px 0',
        boxShadow: '5px 5px 20px #0000001A',
      },
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        height: 44,
        width: 225,
        marginLeft: theme.spacing(3),
        padding: 0,
        borderRadius: '1rem',
        background: palette.primary.light,
        '&.active': {
          background:
            'linear-gradient(270deg, #ffffff 0%, #f9f9f9 68%, #e8e5e5 100%)',
        },
      },
    },
  },

  MuiListItemText: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(1.375),
        marginBottom: theme.spacing(1.125),
        color: palette.info.main,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        textTransform: 'capitalize',
      },
    },
  },

  MuiPagination: {
    styleOverrides: {
      root: {
        '& .Mui-selected': {
          backgroundColor: `${palette.skillWillColors.main} !important`,
          borderRadius: 2,
          opacity: 1,
          color: palette.primary.main,
        },
        fontSize: 20,
        background: palette.primary.light,
        paddingBlock: 16,
        paddingInline: 25,
        borderRadius: 8,
        boxShadow: '0px 0px 10px #0000000D',
        [theme.breakpoints.only('xs')]: {
          paddingInline: 2,
        },
        '& .MuiButtonBase-root': {
          fontSize: 20,
          fontWeight: 500,
        },
        '& .MuiPaginationItem-previousNext': {
          marginInline: 15,
          [theme.breakpoints.only('xs')]: {
            marginInline: 0,
          },
          '& svg': {
            transform: 'scale(1.5)',
          },
        },
      },
    },
  },

  MuiCard: {
    variants: [
      {
        props: {
          variant: 'elevation',
        },
        style: {
          width: '100%',
          border: `1px solid ${palette.primary.contrastText}`,
          borderRadius: '16px',
          boxShadow: 'none',
          padding: '24px',
        },
      },
    ],
  },

  // MuiInput: {
  //   styleOverrides: {
  //     root: {
  //       color: 'red',
  //     },
  //     underline: {
  //       '.MuiTextField-root &': {
  //         transition: 'box-shadow 0.3s',
  //         '&::after, &:hover::before': {
  //           borderWidth: '1px',
  //         },
  //         '&$focused': {
  //           boxShadow: `0 0 1px ${palette.primary.main} inset`,
  //           '&$error': {
  //             boxShadow: `0 0 1px ${palette.error.main} inset`,
  //           },
  //         },
  //         '& input': textFieldInputBase,
  //       },
  //       '&::before': {
  //         borderBottomColor: palette.grey['300'],
  //       },
  //       '&:hover:not($disabled)::before': {
  //         borderBottomColor: palette.grey['300'],
  //       },
  //       '&.text, &.chip': {
  //         '&::before, &::after': {
  //           borderBottom: '0 !important',
  //         },
  //       },
  //       '&.chip': {
  //         '& $input': {
  //           padding: 0,

  //           '& .MuiChip-root': {
  //             paddingRight: 15,
  //             cursor: 'pointer',
  //             height: inputHeight,
  //             borderRadius: 20,
  //             fontSize: 15,

  //             '& .MuiChip-label': {
  //               display: 'flex',
  //               alignItems: 'center',
  //             },
  //           },
  //         },
  //         '& .MuiSelect-icon': {
  //           right: 10,
  //           color: palette.text.primary,
  //         },
  //       },
  //     },
  //   },
  // },
  // MuiOutlinedInput: {
  //   styleOverrides: {
  //     root: {
  //       '&:hover $notchedOutline': {
  //         borderColor: palette.grey['300'],
  //       },
  //       '&$focused $notchedOutline': {
  //         borderWidth: '1px',
  //       },
  //     },
  //     input: textFieldInputBase,
  //     adornedEnd: {
  //       paddingRight: 0,
  //     },
  //     notchedOutline: {
  //       transition: 'all 0.2s ease',
  //       borderColor: palette.grey['300'],
  //     },
  //     multiline: {
  //       padding: '0 0 0 12px',
  //     },
  //     inputMultiline: {
  //       padding: '8px 12px 8px 0',
  //       resize: 'unset',
  //       overflow: 'auto !important',
  //     },
  //   },
  // },
  /* MuiInputAdornment: {
    positionEnd: {
      transition: 'all 0.2s ease',
      height: inputHeight,
      maxHeight: inputHeight,
      width: 45,
      minWidth: 45,
      justifyContent: 'center',
      backgroundColor: palette.grey['200'],
      borderRadius: '0 4px 4px 0',
      zIndex: 1,
      border: `1px solid ${palette.grey['300']}`,
      fontSize: 12,

      '.Mui-focused &': {
        borderLeftColor: palette.primary.main,
      },
      '.Mui-error &': {
        borderLeftColor: palette.error.main,
      },
    },
  },
  MuiTextField: {
    root: {
      width: '100%',
    },
  },
  MuiSwitch: {
    root: {
      width: 30,
      height: 15,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(15px)',
        '& + $track': {
          opacity: 1,
        },
        '&$disabled + $track': {
          backgroundColor: palette.primary.main,
          opacity: 0.5,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: 'none',
      color: 'white',
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: palette.grey['400'],
      '&$disabled': {
        color: 'white',
      },
    },
  },


  MuiFormControlLabel: {
    root: {
      width: '100%',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginRight: 0,
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0,
    },
    labelPlacementTop: {
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'flex-start',
    },
    labelPlacementBottom: {
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'flex-start',
    },
  }, */
  MuiSlider: {
    styleOverrides: {
      root: {
        color: 'success.main',
        padding: '0px!important',
        height: '8px!important',
        marginBottom: '0px',
      },
      markLabel: {
        top: '-26px',
        fontSize: '16px',
        color: '#343A40',
        '@media(pointer: coarse)': {
          top: '-20px',
        },
      },
      mark: {
        display: 'none',
      },
      thumb: {
        width: '0px',
        '& .MuiSlider-valueLabelOpen': {
          color: 'grey',
          backgroundColor: 'transparent',
          padding: '0px',
          top: '0px',
        },
      },
      track: {
        color: '#AB7DF4',
        height: '70%',
      },
    },
  },
};

export default inputs;
