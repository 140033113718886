import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchCurrencies } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { CurrencyModel } from 'src/models/formData';

type Params = {
  onSuccess?: (data: CurrencyModel[]) => void;
}

export const useCurrencies = ({ onSuccess }: Params = {}) => {
  const {
    data, isLoading,
  } = useQuery<CurrencyModel[]>(QUERY_KEYS.CURRENCIES, fetchCurrencies, {
    staleTime: Infinity,
    onSuccess,
  });

  const getCurrencyId = useCallback(
    (curStr: string) => {
      const curId = data?.find(({ abbreviation }) => abbreviation === curStr)?.id;

      return curId ? String(curId) : '';
    },
    [data],
  );

  const getCurrencySymbol = useCallback(
    (curStr: number) => data?.find(({ id }) => id === curStr)?.symbol || '&#36;',
    [data],
  );

  return {
    currencies: data, isLoading, getCurrencyId, getCurrencySymbol,
  };
};

export default useCurrencies;
