import palette from 'src/themes/palette';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const dataDisplay: ComponentsOverrides = {
  MuiTooltip: {
    arrow: {
      color: palette.text.primary,
    },
    tooltip: {
      borderRadius: 0,
      backgroundColor: palette.text.primary,
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 1.7,
      maxWidth: 450,
    },
    tooltipPlacementTop: {
      marginBottom: 12,
    },
    tooltipPlacementBottom: {
      marginTop: 12,
    },
  },
  MuiTableCell: {
    head: {
      fontSize: '0.8rem',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    body: {
      fontSize: '1rem',
    },
  },
};

export default dataDisplay;
