import palette from 'src/themes/palette';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

const feedback: ComponentsOverrides = {
  MuiAlert: {
    root: {
      borderRadius: 0,
      width: '100%',
    },
    filledSuccess: {
      backgroundColor: palette.primary.main,
    },
  },
  MuiDialog: {
    root: {
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    },
    paper: {
      'box-shadow': 'none',
      position: 'relative',
      width: '450px',
      margin: '0 9px',
      padding: '25px 30px',
      background: '#fff',
    },
  },
  MuiBackdrop: {
    root: {
      'background-color': 'rgba(0,0,0,0.3)',
    },
  },
};

export default feedback;
