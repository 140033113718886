import { MutableRefObject, RefObject, SetStateAction } from 'react';
import { ALERT_TYPES, LocalStorageEnum, PASSWORD_VALIDATIONS, RoleEnum } from 'src/enums';
import { emailValidation, passwordVaildator } from 'src/helpers/select/validations/dataValidations';
import decode from 'jwt-decode';
import { AxiosError } from 'axios';
import { DataResponse } from 'src/models/error';
import { v4 as uuidv4 } from 'uuid';
import { ClientModel, FreelancerSkills } from 'src/models/user';
import { ERROR_KEYS_TO_IGNORE } from 'src/constants';
import { TFunction } from 'react-i18next';
import { ParsedQs } from 'qs';
import { NavigateFunction } from 'react-router-dom';
import { SelectChangeEvent, TextFieldProps } from '@mui/material';
import { ArrayItem, ConversationIDResponse } from 'src/models/general';

export type CustomJwtPayload = { role: RoleEnum; user_id: number; };

export const dateFormat = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${day > 9 ? day : `0${day}`}-${month > 9 ? month : `0${month}`}-${date.getFullYear()} ${
    hour > 9 ? hour : `0${hour}`
  }:${minute > 9 ? minute : `0${minute}`}`;
};

export const formatJobDate = (strDate: string) => {
  const date = new Date(strDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day > 9 ? day : `0${day}`}.${month > 9 ? month : `0${month}`}.${date.getFullYear()}`;
};

export const timeSince = (translate: TFunction<'translations', undefined>, date: Date) => {
  const seconds = Math.floor((Number(new Date()) - Number(date)) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return translate('Date.yearsAgo', {
      count: Math.floor(interval),
    });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return translate('Date.monthsAgo', {
      count: Math.floor(interval),
    });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return translate('Date.daysAgo', {
      count: Math.floor(interval),
    });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return translate('Date.hoursAgo', {
      count: Math.floor(interval),
    });
  }
  interval = seconds / 60;
  if (interval > 1) {
    return translate('Date.minutesAgo', {
      count: Math.floor(interval),
    });
  }
  return translate('Date.secondsAgo', {
    count: Math.abs(Math.ceil(interval)),
  });
};

export const getFormattedCurrentDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const getDate = date.getDate();
  const getMonth = date.getMonth() + 1;
  const day = getDate < 10 ? `0${getDate}` : getDate;
  const month = getMonth < 10 ? `0${getMonth}` : getMonth;
  const year = new Date(date).getFullYear();

  return `${day}.${month}.${year}`;
};

export const getFirstExisting = (strArray: string[]) => strArray.find((str) => !!str) || '';

export const splitOn = (char: string, str: string) => str.split(char)[0];

export const parseEmail = (email: string) => splitOn('@', email);

export const scrollToElement = (ref: MutableRefObject<HTMLElement | null>) => {
  if (!ref.current) {
    return;
  }
  window.scrollTo({
    top: ref.current.offsetTop + 5,
    left: 0,
    behavior: 'smooth',
  });
};

export const getTouchedObj = (errors: any) => {
  const touched: any = {};
  Object.keys(errors).forEach((key) => {
    if (Array.isArray(errors[key])) {
      errors[key].forEach((val: any, index: any) => {
        if (index === 0) {
          touched[key] = [];
        }
        touched[key].push(getTouchedObj(val));
      });
    } else {
      touched[key] = false;
    }
  });

  return touched;
};

type PlainObject<T = unknown> = {
  [key: string]: T;
};

function isPlainObject(obj: unknown): obj is PlainObject {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export const snakeToCamelCase = (str: string) => str.replace(/([_][a-z])/ig, (letter) => letter.toUpperCase()
  .replace('_', ''));

export function transformKeysToCamelCase<T>(input: T, excludeFields?: string[]): T {
  if (isPlainObject(input)) {
    const output: PlainObject = {};

    Object.keys(input).forEach((key: string) => {
      const valueObj = transformKeysToCamelCase((input as PlainObject)[key], excludeFields);
      if (excludeFields?.includes(key)) {
        if (typeof valueObj === 'object') {
          output[snakeToCamelCase(key)] = (input as PlainObject)[key];
        }
      } else {
        output[snakeToCamelCase(key)] = valueObj;
      }
    });

    return output as T;
  }
  if (Array.isArray(input)) {
    return input.map((value) => transformKeysToCamelCase(value, excludeFields)) as unknown as T;
  }

  return input;
}

function camelToSnakeCase(camelCase: string): string {
  return camelCase.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
export function transformKeysToSnakeCase<T>(input: T, excludeFields?: string[]): T {
  if (isPlainObject(input)) {
    const output: PlainObject = {};
    Object.keys(input).forEach((key: string) => {
      const valueObj = transformKeysToSnakeCase((input as PlainObject)[key], excludeFields);
      if (excludeFields?.includes(key)) {
        if (typeof valueObj === 'object') {
          output[camelToSnakeCase(key)] = (input as PlainObject)[key];
        }
      } else {
        output[camelToSnakeCase(key)] = valueObj;
      }
    });
    return output as T;
  }
  if (Array.isArray(input)) {
    return input.map((value) => transformKeysToSnakeCase(value, excludeFields)) as T;
  }
  return input;
}

export const isInsideRef = (ref: MutableRefObject<HTMLElement | null>) =>
  ref.current
  && window.pageYOffset > ref.current.offsetTop
  && window.pageYOffset <= ref.current.offsetTop + ref.current.offsetHeight;

export const isScrolledToBottom = () =>
  Math.round(window.innerHeight + window.pageYOffset) >= document.body.offsetHeight;
export const matchString = (str: string, toMatch: string) => {
  const matcher = new RegExp(toMatch, 'gi');
  return matcher.test(str);
};

export function listTitlesWithCommas<T extends { title: string; }>(arr: T[]) {
  return arr.map(({ title }) => title).join(', ');
}

export function getInitialsByFullName(name: string, lastName: string): string {
  if (!name || !lastName) {
    return '';
  }
  const nameInitial = name.length ? name[0] : '';
  const lastNameInitial = lastName.length ? lastName[0] : '';

  return `${nameInitial.toUpperCase()}.${lastNameInitial.toUpperCase()}`;
}

export const getTitles = (skills: FreelancerSkills[]): string => skills.map((skill) => skill.title).join(', ');

export function getInitialsByName(name: string) {
  const firstCharachter = name.length ? name[0] : '';
  const secondCharachter = name.split(' ')[1];
  if (secondCharachter) {
    return `${firstCharachter.toUpperCase()}.${secondCharachter[0].toUpperCase()}`;
  }
  return firstCharachter.toUpperCase();
}

export function downloadCV(fileUrl: string | null) {
  if (fileUrl) {
    window.open(fileUrl, '_blank');
  }
}

export function removeOneTimeJWT() {
  localStorage.removeItem(LocalStorageEnum.ONE_TIME_JWT);
}

export function validateEmail(email: string) {
  const matcher = new RegExp(emailValidation, 'gi');
  return matcher.test(email);
}

export const decodeToken = (token: string) => decode(token) as CustomJwtPayload;

export const getDefaultTabValue = (path: string, obj: { [s: number]: string; }) => {
  const lastPathItem = path.substring(path.lastIndexOf('/') + 1).replace('-', '_');

  const valueObject = Object.entries(obj).find(([key]) => key.toLowerCase() === lastPathItem.toLowerCase());

  return valueObject ? +valueObject[1] : 0;
};

export const checkIfChatUnread = ({
  jobId,
  freelancerUserId,
  unseenConversationIds,
  conversationWithIds,
}: {
  jobId?: number;
  freelancerUserId?: number;
  unseenConversationIds?: number[];
  conversationWithIds?: ConversationIDResponse[];
}) => {
  const conversationObject = conversationWithIds?.find((val) => {
    if (!jobId && freelancerUserId) {
      return val.freelancerUserId === freelancerUserId && !val.jobId;
    }
    if (jobId && !freelancerUserId) {
      return val.jobId === jobId && !val.freelancerUserId;
    }
    return val.freelancerUserId === freelancerUserId && val.jobId === jobId;
  });
  return unseenConversationIds && conversationObject && unseenConversationIds?.includes(conversationObject.id);
};

// const getErrorMessages = (keys: string[], errData: DataResponse) => {
//   const messages: string[] = [];
//   keys.forEach((key) => {
//     if (Array.isArray(errData[key]) && !ERROR_KEYS_TO_IGNORE.includes(key)) {
//       messages.push(errData[key][0]);
//     }
//     if (typeof errData[key] === 'string') {
//       messages.push(errData[key].toString());
//     }
//   });
//   return messages;
// };

const getErrorMessages = (keys: string[], errData: DataResponse) => {
  const messages: string[] = [];
  keys.forEach((key) => {
    const value = errData[key];
    if (Array.isArray(value) && !ERROR_KEYS_TO_IGNORE.includes(key)) {
      value.forEach((item) => {
        if (typeof item === 'string') {
          messages.push(item);
        } else if (typeof item === 'object') {
          Object.keys(item).forEach((itemKey) => {
            const message = item[itemKey];
            if (typeof message === 'string') {
              messages.push(message);
            }
          });
        }
      });
    } else if (typeof value === 'string') {
      messages.push(value);
    }
  });
  return messages;
};

export const getErrorMesaagesList = (error: AxiosError) => {
  const errData = error.response?.data as DataResponse;
  const messages: string[] = [];
  const keys = Object.keys(errData);
  if (Array.isArray(errData)) {
    errData.forEach((err) => {
      const msgs = getErrorMessages(Object.keys(err), err);
      messages.push(msgs.join());
    });
    return messages;
  }

  return getErrorMessages(keys, errData);
};

export const countUnfilledFields = (client: ClientModel) => {
  let unFilledFieldsCount = 0;

  if (!client.address) {
    unFilledFieldsCount += 1;
  }

  if (!client.company_id) {
    unFilledFieldsCount += 1;
  }

  if (!client.country) {
    unFilledFieldsCount += 1;
  }

  if (!client.name) {
    unFilledFieldsCount += 1;
  }

  if (!client.linkedin) {
    unFilledFieldsCount += 1;
  }

  if (!client.website) {
    unFilledFieldsCount += 1;
  }

  return unFilledFieldsCount;
};

export const httpUrlToWebSockeUrl = (httpUrl: string) => httpUrl.replace(/(http)(s)?:\/\//, 'ws$2://');

export const generateErrorMessages = (messages: string[], type: ALERT_TYPES) =>
  messages.map((msg) => ({
    id: uuidv4(),
    message: msg,
    type,
  }));

export const showScrollBar = () => {
  document.body.style.overflowY = 'auto';
};

export const hideScrollBar = () => {
  document.body.style.overflowY = 'hidden';
};

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
};

export const showScrollBarMuiSelect = () => {
  document.body.style.overflowY = 'auto';
  document.body.style.paddingRight = '0px';
};

export const hideScrollBarMuiSelect = () => {
  document.body.style.overflowY = 'hidden';
  if (document.body.scrollHeight > window.innerHeight) {
    document.body.style.paddingRight = `${getScrollbarWidth()}px`;
  }
};

export const validatePassword = (value: string) => {
  const errors = [];
  if (!value.match(passwordVaildator.digit)) {
    errors.push(PASSWORD_VALIDATIONS.NUMBER);
  }
  if (!value.match(passwordVaildator.uppercase)) {
    errors.push(PASSWORD_VALIDATIONS.UPPERCASE);
  }
  if (!value.match(passwordVaildator.symbol)) {
    errors.push(PASSWORD_VALIDATIONS.SYMBOL);
  }
  if (value.length < 6) {
    errors.push(PASSWORD_VALIDATIONS.LENGTH);
  }
  return errors;
};

export const fromHtmlEntities = (str: string) =>
  `${str}`.replace(/&#\d+;/gm, (st: string) => String.fromCharCode(Number(st.match(/\d+/gm)![0])));

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const getShortendString = (filename: string, maxLength: number = 10, cutLength: number = 5) => {
  let name;
  const split = filename.split('.');
  [name] = split;
  const extension = split[1];
  if (name.length > maxLength) {
    name = `${name.slice(0, cutLength)}...${name.slice(name.length - cutLength)}.${extension}`;
  } else {
    name = filename;
  }
  return name;
};

export const getShortenedEmail = (filename: string, maxLength: number = 10, cutLength: number = 8) => {
  let name;
  const index = filename.lastIndexOf('.');
  name = filename.substring(0, index);
  const extension = filename.substring(index, filename.length);
  if (name.length > maxLength) {
    name = `${name.slice(0, cutLength)}...${extension}`;
  } else {
    name = filename;
  }
  return name;
};

export const getNewestDate = (dates: string[]) =>
  new Date(Math.max(...dates.map((date) => new Date(date).getTime()))).toISOString().split('T')[0];

export const handleSearch = (
  str: string,
  pathname: string,
  navigate: NavigateFunction,
  getStringFromQuery: (query: any) => string,
) => {
  const result = getStringFromQuery({
    search: str,
  });
  navigate({
    pathname,
    search: result,
  });
};

export const handleSkillChange = (
  event: SelectChangeEvent<string[]>,
  query: ParsedQs,
  pathname: string,
  getStringFromQuery: (query: any) => string,
  navigate: NavigateFunction,
  setSkillsValue: (value: SetStateAction<string[]>) => void,
  searchRef: RefObject<TextFieldProps>,
) => {
  const { value } = event.target;
  const str = searchRef.current?.value;
  if (value.at(-1) === 'All') {
    const result = getStringFromQuery({
      search: str,
    });
    setSkillsValue([]);
    navigate({
      pathname,
      search: result,
    });
    return;
  }
  const skillsArr = typeof value === 'string' ? value.split(',') : value;
  const result = getStringFromQuery({
    ...query,
    skills: skillsArr || null,
    search: str,
  });
  navigate({
    pathname,
    search: result,
  });
  setSkillsValue(skillsArr);
};

export const handlePageChange = (
  query: ParsedQs,
  pathname: string,
  getStringFromQuery: (query: any) => string,
  navigate: NavigateFunction,
  currentPage: number,
) => {
  const result = getStringFromQuery({
    ...query,
    page: currentPage,
  });
  navigate({
    pathname,
    search: result,
  });
};

export default {
  dateFormat,
};

export const copyToClipboard = async (textToCopy: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

export const createNameValuePair = (
  name: string,
  val: string | number | undefined,
) => ({
  name, value: val,
});

export const flattenObjectValues = <T>(obj: Record<number, T[]>): T[] => Object.values(obj).flat();

export const removeItemsByKey = <T>(obj: Record<number, T[]>, page: number): Record<number, T[]> =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => +key !== page));

export const createChunkArray = (array: ArrayItem[] | undefined, size: number): ArrayItem[][] | undefined =>
  array?.reduce<ArrayItem[][]>((result, item, index: number) => {
    const chunkIndex = Math.floor(index / size);
    if (!result[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
