import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { fetchPhoneCodes } from 'src/api';
import { DEFAULT_PHONE_COUNTRY } from 'src/constants';
import { QUERY_KEYS, PublicFreelancerRoutesEnum } from 'src/enums';

export const usePhoneCodes = () => {
  const location = useLocation();

  const isFreelancer
    = location.pathname === PublicFreelancerRoutesEnum.FREELANCER_CREATE_ACCOUNT;

  const { data } = useQuery(QUERY_KEYS.PHONE_CODES, fetchPhoneCodes, {
    staleTime: Infinity,
    enabled: !isFreelancer,
  });

  function getPhoneCode(codeId: number) {
    return data?.find(({ id }) => id === codeId) || '';
  }

  const getDefaultPhoneCode = () =>
    data?.find(({ country }) => country === DEFAULT_PHONE_COUNTRY)?.id || '';

  return {
    phoneCodes: data,
    getPhoneCode,
    getDefaultPhoneCode,
  };
};

export default usePhoneCodes;
