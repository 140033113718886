import {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MessageModel } from 'src/models/error';
import { generateErrorMessages } from 'src/utils';
import { ALERT_TYPES } from 'src/enums';

export interface AlertContextInterface {
  alertMessages: MessageModel[];
  setAlertMessages: Dispatch<SetStateAction<MessageModel[]>>;
  addAlertMessages: (messages: string[], type?: ALERT_TYPES) => void;
  removeAlertMessage: (msg: string) => void;
}

export const AlertContext = createContext<AlertContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const AlertContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [alertMessages, setAlertMessages] = useState<MessageModel[]>([]);

  const addAlertMessages = useCallback(
    (messages: string[], type: ALERT_TYPES = ALERT_TYPES.ERROR) => {
      const transformedMessages = generateErrorMessages(messages, type);
      setAlertMessages([...transformedMessages]);
    },
    [],
  );

  const removeAlertMessage = useCallback(
    (msgId: string) => {
      setAlertMessages(alertMessages.filter(({ id }) => id !== msgId));
    },
    [alertMessages],
  );

  const contextValues = useMemo(
    () => ({
      alertMessages,
      setAlertMessages,
      addAlertMessages,
      removeAlertMessage,
    }),
    [alertMessages, addAlertMessages, removeAlertMessage],
  );

  useEffect(() => () => setAlertMessages([]), []);

  return (
    <AlertContext.Provider value={ contextValues }>
      { children }
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;

export const useAlertModal = () => {
  const value = useContext(AlertContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
