import { useQuery } from 'react-query';
import { fetchCountries } from 'src/api';
import { QUERY_KEYS } from 'src/enums';

export const useCountry = () => {
  const { data } = useQuery(QUERY_KEYS.COUNTRIES, fetchCountries, {
    staleTime: Infinity,
  });

  function getCountryTitle(countryId: number) {
    return data?.find(({ id }) => id === countryId)?.title || '';
  }

  return {
    countries: data, getCountryTitle,
  };
};

export default useCountry;
