export const pageSize = 5;

export const DEFAULT_SALARY_CURRANCY = 'USD';

export const TIMEOUT = 3000;

export const QUERY_LIMIT = 10;

export const SM_PAGE_SIZE = 8;

export const MD_PAGE_SIZE = 11;

export const PENDING_FREELANCER_PAGE_SIZE = 10;

export const DEFAULT_PHONE_COUNTRY = 'GE';

export const FORM_REQUIRED_MESSAGE = 'Please fill all required fields.';

export const DEFAULT_MODAL_TIMEOUT = 5000;

export const DEFATULT_SENIORITY_TITLE = 'Any';

export const HIRING_TALENT = 'TALENT';

export const ERROR_KEYS_TO_IGNORE = ['linkedin'];

export const DEFAULT_SLIDER_DELAY = 5000;
