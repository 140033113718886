import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchSeniorities } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { SkillModel } from 'src/models/formData';

export const useSeniorities = () => {
  const {
    data, isLoading,
  } = useQuery<SkillModel[]>(QUERY_KEYS.SENIORITIES, fetchSeniorities, {
    staleTime: Infinity,
  });

  const getSeniorityTitle = useMemo(() => (seniorityLevelId: number) => {
    if (data) {
      return data?.find(({ id }) => id === seniorityLevelId)?.title || '';
    }
    return '';
  }, [data]);

  return {
    seniorities: data, isLoading, getSeniorityTitle,
  };
};

export default useSeniorities;
