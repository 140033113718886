import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { changeJobStatus, fetchJobs, deleteJob } from 'src/api';
import { QUERY_LIMIT } from 'src/constants';
import { useAuth } from 'src/context/authContext';
import { QUERY_KEYS } from 'src/enums';
import useQueryString from 'src/hooks/useQueryString';
import {
  JobModelWithClientData,
  JobPostingsResponse,
} from 'src/models/general';

type MutationReturn = {
  prevData: JobPostingsResponse | undefined;
};
/* eslint-disable sonarjs/cognitive-complexity */
export const useJobs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuth();

  const {
    parseQuery, getStringFromQuery,
  } = useQueryString();

  let result = {};
  const {
    search,
    profession,
    country,
    limit,
    page = 1,
    ordering,
  } = parseQuery();
  result = {
    user__client__country: country,
    search,
    profession,
    ordering,
    limit: limit ? +limit : QUERY_LIMIT,
    page: !Number.isNaN(Number(page)) ? Number(page) : 1,
  };
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEYS.JOBS, result],
    () => fetchJobs(result),
    {
      enabled: isLoggedIn,
    },
  );

  const { mutate: changeStatus } = useMutation<
    JobModelWithClientData,
    Error,
    { id: number; is_published: boolean; },
    MutationReturn
  >('job-status', (props) => changeJobStatus(props), {
    onMutate: (jobToChange) => {
      const previousJobs = queryClient.getQueryData<JobPostingsResponse>([
        QUERY_KEYS.JOBS,
        result,
      ]);
      queryClient.setQueryData<JobPostingsResponse | undefined>(
        [QUERY_KEYS.JOBS, result],
        (oldJobs) => {
          if (oldJobs) {
            const { results } = oldJobs;
            const found = results.find((oldJob) => oldJob.id === jobToChange.id);
            if (found) {
              found.is_published = jobToChange.is_published;
              queryClient.setQueryData(['job', found.id.toString()], found);
            }
            return oldJobs;
          }
          return undefined;
        },
      );

      return {
        prevData: previousJobs,
      };
    },
    onError: (_err, __props, context) => {
      queryClient.setQueryData(QUERY_KEYS.JOBS, context?.prevData);
    },
  });

  const {
    mutate: deleteJobyId, isLoading: isDeletingJob,
  } = useMutation<
    any,
    any,
    number | string,
    MutationReturn
  >('delete-job', (id) => deleteJob(String(id)), {
    onMutate: (jobIdToDelete) => {
      const previousJobs = queryClient.getQueryData<
        JobPostingsResponse | undefined
      >(QUERY_KEYS.JOBS);

      queryClient.setQueryData<JobPostingsResponse | undefined>(
        [QUERY_KEYS.JOBS, result],
        (oldJobs) => {
          if (oldJobs) {
            const newJobs = {
              ...oldJobs,
            };
            newJobs.results = newJobs.results.filter(({ id }) => id !== jobIdToDelete);
            if (Number(page) > 1 && newJobs.results.length === 0) {
              const newQuery = getStringFromQuery({
                ...result,
                page: Number(page) - 1,
              });
              navigate({
                pathname,
                search: newQuery,
              });
            }
            return newJobs;
          }
          return undefined;
        },
      );

      return {
        prevData: previousJobs,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.JOBS]);
    },
  });

  return {
    jobs: data?.results,
    isLoading,
    isSuccess,
    count: data?.count || 0,
    next: Boolean(data?.next),
    previous: Boolean(data?.previous),
    changeStatus,
    deleteJobyId,
    isDeletingJob,
  };
};

export default useJobs;
