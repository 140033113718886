// eslint-disable sonarjs/no-identical-functions
import {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { LocalStorageEnum, RoleEnum, SessionStorageEnum } from 'src/enums';
import { decodeToken } from 'src/utils';

export interface AuthContextInterface {
  access: string;
  setAccess: Dispatch<SetStateAction<string>>;
  userId: number;
  realUserId: number;
  setUserId: Dispatch<SetStateAction<number>>;
  setRealUserId: Dispatch<SetStateAction<number>>;
  isLoggedIn: boolean;
  handleLogout: () => void;
  role: RoleEnum | null;
  setRole: Dispatch<SetStateAction<RoleEnum | null>>;
  resetPassword: boolean;
  setResetPassword: Dispatch<SetStateAction<boolean>>;
  setLocalTokens: (accessToken: string, refreshToken: string) => void;
  setSessionTokens: (accessToken: string, refreshToken: string) => void;
  isActive: boolean | undefined;
  setIsActive: Dispatch<SetStateAction<boolean | undefined>>;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

// const socket = new WebSocket('ws://projects.dev.sweeftdigital.com:8000/notifications/');
interface Props {
  children: ReactNode;
}

const AuthContextProvider: FC<Props> = ({ children }): ReactElement => {
  const queryClient = useQueryClient();
  const [access, setAccess] = useState(() => localStorage.getItem(LocalStorageEnum.ACCESS)
  || sessionStorage.getItem(SessionStorageEnum.ACCESS) || '');
  const [userId, setUserId] = useState<number>(0);
  const [realUserId, setRealUserId] = useState<number>(0);
  const [role, setRole] = useState<RoleEnum | null>(null);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean | undefined>(undefined);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LocalStorageEnum.ACCESS);
    localStorage.removeItem(LocalStorageEnum.REFRESH_TOKEN);
    sessionStorage.removeItem(SessionStorageEnum.ACCESS);
    sessionStorage.removeItem(SessionStorageEnum.REFRESH_TOKEN);
    setAccess('');
    setUserId(0);
    setRealUserId(0);
    setRole(null);
    setIsActive(undefined);
    queryClient.clear();
  }, [queryClient]);

  const setLocalTokens = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(LocalStorageEnum.ACCESS, accessToken);
    localStorage.setItem(LocalStorageEnum.REFRESH_TOKEN, refreshToken);
    setAccess(accessToken);
  }, []);

  const setSessionTokens = useCallback((accessToken: string, refreshToken: string) => {
    sessionStorage.setItem(SessionStorageEnum.ACCESS, accessToken);
    sessionStorage.setItem(SessionStorageEnum.REFRESH_TOKEN, refreshToken);
    setAccess(accessToken);
  }, []);

  useEffect(() => {
    if (access && !role) {
      const { role: newRole } = decodeToken(access);
      if (!newRole) {
        setLocalTokens('', '');
      } else {
        setRole(newRole);
      }
    }
  }, [access, setRole, role, setLocalTokens]);

  const contextValues = useMemo(
    () => ({
      access,
      setAccess,
      userId,
      setUserId,
      realUserId,
      setRealUserId,
      isLoggedIn:
        !!localStorage.getItem(LocalStorageEnum.ACCESS) || !!sessionStorage.getItem(SessionStorageEnum.ACCESS),
      handleLogout,
      setLocalTokens,
      setSessionTokens,
      role,
      setRole,
      resetPassword,
      setResetPassword,
      isActive,
      setIsActive,
    }),
    [access, resetPassword, role, setLocalTokens, setSessionTokens, handleLogout, userId, realUserId, isActive],
  );

  return <AuthContext.Provider value={ contextValues }>{ children }</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuth = () => {
  const value = useContext(AuthContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
