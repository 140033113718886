export enum RoleEnum {
  PROVIDER = 'service_provider',
  CLIENT = 'client',
  FREELANCER = 'freelancer',
}

export enum UserEnum {
  MEMBER = '2',
  FREELANCER = '1'
}

export enum OperationsEnum {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum ParamedRoutes {
  CLIENT_JOB_DETAILS = '/client/requests',
  PROVIDER_JOB_DETAILS = '/service/requests',
  RESET_PASSWORD = '/client/login/reset-password',
  MATCHES = '/service/requests/match',
  EDIT_MEMBER = '/service/stacks/edit-member',
  CLIENT = '/service/clients',
  SALARY_RATES = '/service/match',
  FREELANCER = '/service/freelancers',
  EDIT_FREELANCER = '/service/stacks/edit-freelancer',
  FREELANCER_JOB_DETAILS = '/freelancer/requests',
}

export enum PrivateClientRoutesEnum {
  CLIENT_JOBDETAILS = '/client/requests/:jobId/',
  CLIENT_JOB_MATCHES = '/client/requests/:jobId/matches',
  CLIENT_REQUESTS = '/client/requests',
  CLIENT_NEW_JOB_REQUEST = '/client/requests/new-request',
  CLIENT_NOTIFICATIONS = '/client/notifications',
  CLIENT_CHATS = '/client/chats',
  CLIENT_PROFILE = '/client/profile',
}

export enum PrivateFreelancerRoutesEnum {
  FREELANCER_PROFILE = '/freelancer/profile',
  FREELANCER_REQUESTS = '/freelancer/requests',
  FREELANCER_NOTIFICATIONS = '/freelancer/notifications',
  FREELANCER_JOBDETAILS = '/freelancer/requests/:jobId',
  FREELANCER_CHATS = '/freelancer/chats',
  FREELANCER_CHAT = '/freelancer/chat',
  FREELANCER_JOB_CHAT = '/freelancer/requests/:jobId/chat',
  FREELANCER_CAREER_TIPS = '/freelancer/career-tips'
}

/* eslint-disable sonarjs/no-duplicate-string */
export enum PrivateFreelancerAbsoluteRoutes {
  FREELANCER_PROFILE = 'profile',
  FREELANCER_REQUESTS = 'requests',
  FREELANCER_NOTIFICATIONS = 'notifications',
  FREELANCER_JOBDETAILS = 'requests/:jobId',
  FREELANCER_JOB_DETAILS_BACKUP = 'details',
  FREELANCER_CHAT = 'chat',
  FREELANCER_JOB_MATCHES = 'matches',
  FREELANCER_CHATS = 'chats',
  FREELANCER_CAREER_TIPS = 'career-tips',
  FREELANCER_USER_DESCRIPTION = ':userRole/:hash',
}

export enum PrivateClientAbsoluteRoutes {
  CLIENT_JOBDETAILS = 'requests/:jobId',
  CLIENT_JOB_DETAILS_BACKUP = 'details',
  CLIENT_JOB_MATCHES = 'matches',
  CLIENT_JOB_CHAT = 'chat',
  CLIENT_CHATS = 'chats',
  CLIENT_REQUESTS = 'requests',
  CLIENT_NEW_JOB_REQUEST = 'requests/new-request',
  CLIENT_USER_DESCRIPTION = 'requests/:userRole/:hash',
  CLIENT_NOTIFICATIONS = 'notifications',
  CLIENT_PROFILE = 'profile',
}

export enum ServicePrivateRoutesEnum {
  HOME = '/service/requests',
  JOB_DETAILS = '/service/requests/:jobId',
  JOB_MATCHES = '/service/requests/:jobId/matches',
  JOB_CHAT = '/service/requests/:jobId/:chat',
  CHATS = '/service/chats',
  NOTIFICATIONS = '/service/notifications',
  STACKS = '/service/stacks',
  ADD_NEW_MEMBER = '/service/stacks/new-member',
  EDIT_MEMBER = '/service/stacks/edit-member/:memberId/',
  MATCHES = '/service/requests/match/:jobId',
  SALARY_RATES = '/service/match/:jobId/salary-rates',
  PROFILE = '/service/profile',
  CLIENTS = '/service/clients',
  CLIENT = '/service/clients/:clientId',
  FREELANCERS = '/service/freelancers',
  FREELANCER = '/service/freelancer/:freelancerId',
  BLOGS = '/service/blogs',
}

export enum ServicePrivateAbsoluteRoutes {
  HOME = 'requests',
  JOB_DETAILS_BACKUP = 'details',
  JOB_DETAILS = 'requests/:jobId',
  JOB_MATCHES = 'matches',
  JOB_CHAT = 'chat',
  CHATS = 'chats',
  NOTIFICATIONS = 'notifications',
  STACKS = 'stacks',
  ADD_NEW_MEMBER = 'stacks/new-member',
  EDIT_MEMBER = 'stacks/edit-member/:memberId/',
  MATCHES = 'requests/match/:jobId',
  SALARY_RATES = 'match/:jobId/salary-rates',
  PROFILE = 'profile',
  CLIENTS = 'clients',
  CLIENT = 'clients/:clientId',
  FREELANCERS = 'freelancers',
  FREELANCER = 'freelancers/:freelancerId',
  USER = '/service/:userRole/:hash',
  FREELANCER_JOB_CHAT = 'freelancer-chat',
  BLOGS = 'blogs',
}

export enum PublicClientRoutesEnum {
  CLIENT_CREATE_ACCOUNT = '/client/public/create-account',
  CLIENT_LOOKING_FOR = '/client/public/looking-for',
  CLIENT_VERIFY_TOKEN = '/client/public/verify-token/:token',
}

export enum PublicFreelancerRoutesEnum {
  FREELANCER_CREATE_ACCOUNT = '/freelancer/public/create-account',
  FREELANCER_INFO = '/freelancer/public/personal-info',
}

export enum PublicClientAbsoluteRoutes {
  CLIENT_CREATE_ACCOUNT = 'create-account',
  CLIENT_LOOKING_FOR = 'looking-for',
}

export enum PublicFreelancerAbsoluteRoutes {
  FREELANCER_CREATE_ACCOUNT = 'create-account',
  FREELANCER_INFO = 'personal-info',
}

export enum LoginRoutesEnum {
  LOGIN = '/login',
  FORGET_PASSWORD = '/login/forgot-password',
  CHECK_EMAIL = '/login/check-email',
  CLIENT_VERIFY_EMAIL = '/login/client-verify-email',
  FREELANCER_VERIFY_EMAIL = '/login/freelancer-verify-email',
  VERIFY_EMAIL = '/login/verify-email',
  RESET_PASSWORD = '/login/reset-password/:token',
  PASSWORD_RESET = '/login/reset-password',
}

export enum LoginAbsoluteRoutes {
  FORGET_PASSWORD = 'forgot-password',
  CHECK_EMAIL = 'check-email',
  CLIENT_VERIFY_EMAIL = 'client-verify-email',
  FREELANCER_VERIFY_EMAIL = 'freelancer-verify-email',
  RESET_PASSWORD = 'reset-password/:token',
}

export enum LocalStorageEnum {
  ACCESS = 'access',
  REFRESH_TOKEN = 'refresh_token',
  ONE_TIME_JWT = 'one_time_jwt',
  FIRST_JOB_ID = 'first_job_id',
  COOKIE_ACCEPTED = 'cookieAccepted',
  LOCALE = 'locale',
  IS_ACTIVE = 'is_active',
}

export enum SessionStorageEnum {
  ACCESS = 'access',
  REFRESH_TOKEN = 'refresh_token',
  IS_ATIVE = 'is_active',
}

export enum SalaryTypesEnum {
  daily = 'Daily',
  monthly = 'Monthly',
  yearly = 'Yearly',
}

export enum ContractTypesEnum {
  FULL_TIME = 'Full Time',
  PART_TIME = 'Part Time',
}

export enum APIRoutesEnum {
  CLIENT = '/client/',
  CLIENT_DEDICATED = '/client/dedicated/',
  PHONE_CODES = '/phone-code/',
  LANGUAGE_LINKS = '/language-link/',
  EDUCATION_LEVELS = '/education-level/',
  LANGUAGES = '/language/',
  CURRENCIES = '/currency/',
  JOB_DURATIONS = '/job-duration/',
  PROFESSIONS = '/profession/',
  SENIORITY_LEVELS = '/seniority-level/',
  SKILLS = '/skill/',
  CHANGE_PASSWORD = '/change-password/',
  RESET_PASSWORD_CONFRIM = '/reset-password/confirm',
  RESET_PASSWORD = '/reset-password/',
  LOGIN = '/login/',
  REGISTER = '/register/',
  REGISTER_FREELANCER = '/freelancer-register/',
  JOB_POSTINGS = '/job-postings/',
  NEW_MEMBER = '/team/',
  USER = '/user/',
  USER_INFO = '/user/info/',
  UNSEEN_CHATS_COUNT = 'unseen-messages-count/',
  SEND_EMAIL = '/freelancer-info',
  COUNTRIES = '/country/',
  VERIFY_TOKEN = '/register/verify/',
  FILE = '/file/',
  CV = '/cv/',
  CANDIDATES = '/team/',
  PROVIDER_ME = '/team/me/',
  CLIENT_ME = '/client/me/',
  NOTIFICATION = '/notifications/',
  CHAT = '/chat/',
  CHAT_ATTACHMENT = '/chat/attachment',
  CHAT_CONVERSATION_ID = '/chat/conversation',
  MARK_CONVERSATION_AS_READ = '/mark-messages-as-read',
  DELETE_NOTIFICATION = '/notifications-delete/',
  NOTIFICATIONS_MARK_ALL_AS_READ = '/notifications-mark-all-as-read/',
  POLICY = '/policy/',
  TALENTS = '/talents/',
  TERMS = '/terms/',
  DELETE_SELECTED_NOTIFICATIONS = '/notifications-delete-selected/',
  PENDING_CLIENTS = '/user/activation/',
  FREELANCER = '/freelancer/',
  FREELANCER_FILE = '/freelancer-file/',
  PENDING_FREELANCERS = '/freelancer-activation/',
  STACKS = '/stacks/',
  MATCHED_FREELANCER_CHAT = '/chat-matched-freelancer/',
  UNMATCHED_FREELANCER_CHAT = '/chat-unmatched-freelancer/',
  SOCIALS='/socials/',
  BLOGS='/blogs/',
  BLOG='/blog/',
  STACK = '/stack/',
}

export enum MemberStatus {
  PENDING = 'Pending',
  DECLINED = 'Declined',
  ACCEPTED = 'Accepted',
  COMPLETED = 'Completed',
  HIRED = 'Hired',
}

export enum MemberToDeleteEnum {
  FREELANCER = 'freelancer',
  TEAM_MEMBER = 'team_member',
}

export enum NotificationTypes {
  MATCHING = 'matching',
  NO_RESPONSE_REMINDER = 'no_response_reminder',
  JOB_REQUEST = 'job_request',
  ACCEPT_DECLINE = 'accept-decline',
  OPEN_JOB_REQUEST = 'open_job_request',
  FREELANCER_ACCEPT = 'accept freelancer',
  FREELANCER_DECLINE = 'decline freelancer',
  CLIENT_REGISTRATION = 'client registration',
  FREELANCER_REGISTRATION = 'freelancer registration',
}

export enum TabOptions {
  DETAILS,
  MATCHES,
  CHAT,
  FREELANCER_CHAT
}

export enum FreelancerTabs {
  Profile,
  Chat,
}

export enum QUERY_KEYS {
  SKILLS = 'skills',
  MEMBER_SKILLS = 'member-skills',
  MEMBER_LANGUAGES = 'member-languages',
  MEMBER_CERTIFICATES = 'member-certificates',
  JOB_DURATIONS = 'job-durations',
  TEAM_MEMBERS = 'team-members',
  CLIENT = 'client',
  CHAT = 'chat',
  CONVERSATION = 'conversation',
  CONVERSATIONS = 'conversations',
  MARK_CONVERSATION_AS_READ = 'mark-conversation-as-read',
  USER_INFO = 'USER_INFO',
  ALL_CLIENTS = 'all-clients',
  CLIENT_PROFILE = 'client-profile',
  PROVIDER_PROFILE = 'provider-profile',
  CONTRACT_TYPES = 'contract-types',
  COUNTRIES = 'countries',
  CURRENCIES = 'currencies',
  EDUCATION_LEVELS = 'education-levels',
  JOB = 'job',
  PROVIDER_JOB_MATCHES = 'job-matches',
  JOBS = 'jobs',
  LANGUAGES = 'languages',
  CLIENT_JOB_MATCHES = 'client-job-matches',
  FREELANCER_JOB_MATCHES = 'freelancer-job-matches',
  TEAM_MEMBER = 'team-member',
  PHONE_CODES = 'phone-codes',
  PROFESSIONS = 'professions',
  SENIORITIES = 'seniorities',
  TALENTS = 'talents',
  DELETE_NOTIFICATIONS = 'delete-notifications',
  NOTIFICATIONS = 'notifications',
  ALL_NOTIFICATIONS = 'all-notifications',
  DELETE_CV = 'delete-cv',
  DEDITACTED_CLIENTS = 'dedicated-clients',
  PENDING_CLIENTS = 'user-activation',
  PENDING_FREELANCERS = 'freelancer-activation',
  DEDICATED_FREELANCERS = 'dedicated-freelancers',
  APPROVE_FREELANCER = 'freelancer-aprove-or-reject',
  FREELANCER = 'freelancer',
  FREELANCER_PROFILE = 'freelancer-profile',
  FREELANCER_JOB_CHAT = 'freelancer-job-chat',
  MATCHED_CLIENTS = 'matched-clients',
  UNSEEN_CHATS = 'unseen-chats',
  FREELANCER_PROJECTS = 'freelancer-projects',
  FREELANCER_SKILLS = 'freelancer-skills',
  FREELANCER_LANGUAGES = 'freelancer-languages',
  FREELANCER_CERTIFICATES = 'freelancer-certificates',
  CHAT_ATTACHMENT = 'chat-attachment',
  SOCIALS = 'socials',
  EDUCATION = 'education',
  BLOGS = 'blogs',
  BLOG = 'blog',
  BLOG_CATEGORIES = 'blog-categories',
  FREELANCER_INFORMATION= 'freelancer-information',
  MEMBER_INFORMATION = 'member-information',
  STACK = 'stack',
}

export enum FEEDBACK_MODAL_ICONS {
  DEFAULT = 'default',
  HOURGLASS = 'hourglass',
}

export enum NOTIFICATIONS_ICON_TYPE {
  DEFAULD = 'default',
  FILLED = 'filled',
}

export enum LOCALES {
  EN = 'en',
  IT = 'it',
}

export enum PASSWORD_VALIDATIONS {
  LENGTH = 'length',
  UPPERCASE = 'uppercase',
  SYMBOL = 'symbol',
  NUMBER = 'number',
  REQUIRED = 'required',
}

export enum START_DATES {
  immediately = 'Immediately',
  in_a_week = 'In a week',
  in_two_weeks = 'In two weeks',
  in_a_month = 'In a month',
  in_two_months = 'In two months',
}

export enum ALERT_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
}
