import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ReactComponent as HourglassIcon } from 'src/assets/hourglassIcon/hourglassIcon.svg';
import { useFeedbackModal } from 'src/context/modalContext';
import { FEEDBACK_MODAL_ICONS } from 'src/enums';

const StyledIcon = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: '5rem',
}));

const FeedbackModal: FC = () => {
  const {
    feedbackModal, icon, constructModal, closeTime,
  } = useFeedbackModal();

  const handleClose = useCallback(() => {
    constructModal({
      title: '',
      iconArg: FEEDBACK_MODAL_ICONS.DEFAULT,
    });
  }, [constructModal]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClose();
    }, closeTime);
    return () => clearTimeout(timeout);
  }, [handleClose, closeTime]);

  return (
    <Dialog
      open={ Boolean(feedbackModal) }
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableAutoFocus
      onClose={ handleClose }
      PaperProps={ {
        style: {
          boxShadow: '5px 5px 15px #0000001A',
          borderRadius: '1rem',
        },
      } }
      disableScrollLock
    >
      <DialogContent
        sx={ {
          width: {
            sm: 512,
            md: 572,
          },
          maxWidth: {
            xs: 352,
            sm: '100%',
          },
          marginTop: {
            md: 4,
            sm: 2,
            xs: 2,
          },
          marginBottom: {
            md: 4,
            sm: 2,
            xs: 0,
          },
        } }
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          rowSpacing={ 2.5 }
        >
          <Grid item sm={ 10 } textAlign='center'>
            { icon === FEEDBACK_MODAL_ICONS.HOURGLASS && <HourglassIcon /> }
            { icon === FEEDBACK_MODAL_ICONS.DEFAULT && <StyledIcon /> }
          </Grid>
          <Grid item sm={ 10 } textAlign='center'>
            <Typography variant='navLink' color='text.primary'>
              { feedbackModal }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 6 }
            marginTop={ 4 }
            width={ {
              xs: 120,
              sm: 200,
              md: 236,
            } }
          >
            <Button
              variant='contained'
              type='submit'
              fullWidth
              sx={ {
                borderRadius: 2,
                fontSize: 16,
                height: {
                  md: 56,
                  sm: 48,
                },
                minWidth: '20px !important',
              } }
              onClick={ handleClose }
            >
              Okay
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackModal;
