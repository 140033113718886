import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchLanguages } from 'src/api';
import { QUERY_KEYS } from 'src/enums';

export const useLanguages = () => {
  const {
    data, isLoading,
  } = useQuery(QUERY_KEYS.LANGUAGES, fetchLanguages, {
    staleTime: Infinity,
  });

  const getLanguages = useCallback((langIds: number[]) => data?.filter((lang) => langIds.includes(lang.id)) || [], [data]);

  const getlanguageTitle = (langId: number) => data?.find(({ id }) => id === langId)?.title || '';

  return {
    languages: data, isLoading, getLanguages, getlanguageTitle,
  };
};

export default useLanguages;
