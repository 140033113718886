import { WiderColor, PaletteOptions, SimplePaletteColorOptions } from '@mui/material';

import { TypeText } from '@mui/material/styles/createPalette';

interface DefaultPaletteOptions extends PaletteOptions {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  error: SimplePaletteColorOptions;
  warning: SimplePaletteColorOptions;
  info: SimplePaletteColorOptions;
  text: TypeText;
  success: SimplePaletteColorOptions;
  icon: TypeText;
  black: string;
  purple: string;
  lightPurple: string;
  activePagination: string;
  mainError: string;
  placeholder: string;
  shadow1: string;
  skillWillColors: SimplePaletteColorOptions;
}

const palette: Omit<DefaultPaletteOptions, 'grey'> & {grey: WiderColor;} = {
  primary: {
    light: '#FFFFFF', main: '#F9F9F9', dark: '#6941C6', contrastText: '#E8E5E5',
  },
  secondary: {
    main: '#05FF32', light: '#21DB79', dark: '#191919', contrastText: '#2d4c54',
  },
  error: {
    main: '#DB521F', light: '#ffe9e9', dark: 'rgb(95, 33, 32)', contrastText: 'rgb(253, 237, 237)',
  },
  warning: {
    main: '#f4d670', light: '#fcf5db', dark: '#a93b31', contrastText: '#21DB79',
  },
  info: {
    main: '#101113', dark: '#659296', light: '#9499a1', contrastText: '#2c2e2f',
  },
  skillWillColors: {
    main: '#AB7DF4', dark: '#A173EA', light: '#1919190D', contrastText: '#2c2e2f',
  },
  success: {
    main: '#38b241', light: '#F2F6FF', dark: '#23233C', contrastText: '#FAAD14',
  },
  icon: {
    primary: '#21DB79', secondary: '#FAAD14', disabled: '#FE4D4F',
  },
  text: {
    primary: '#343A40',
    secondary: '#0D0D0D',
    disabled: '#A4A4A4',
  },
  mandatory: '#F27CAC',
  grey: {
    100: '#acacac',
    200: '#f7f7f7',
    250: '#cdced1',
    300: '#e1e1e1',
    350: '#cecece',
    380: '#bfbec3',
    400: '#adb8c4',
    450: '#86848f',
    500: '#86848f',
    600: '#7c7a85',
    700: '#4d4c52',
    750: '#3b4449',
    800: '#38373d',
    900: '#2f2e32',
  },
  divider: '#4d4c52',
  black: '#000000',
  purple: '#5220B1',
  lightPurple: '#AB7DF414',
  activePagination: '#A9BEDB',
  mainError: '#d32f2f',
  placeholder: '#999999',
  shadow1: '#0000001a',
  background: {
    default: '#f9f9f9',
    paper: '#fff',
  },
};

export default palette;
