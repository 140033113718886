import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchJobDurations } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { JobDurationModel } from 'src/models/general';

export const useJobDurations = () => {
  const {
    data, isLoading,
  } = useQuery<JobDurationModel[]>(QUERY_KEYS.JOB_DURATIONS, fetchJobDurations, {
    staleTime: Infinity,
  });

  const getjobDurationTitle = useMemo(() => (jobDurationId: string) => {
    if (data) {
      return data.find(({ id }) => id === Number(jobDurationId))?.title || '';
    }
    return '';
  }, [data]);


  return {
    jobDurations: data, isLoading, getjobDurationTitle,
  };
};

export default useJobDurations;
