import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createMatches,
  deleteMatched,
  fetchMatchedMembers,
  fetchMembersToMatch,
} from 'src/api';
import { QUERY_LIMIT } from 'src/constants';
import { MemberStatus, QUERY_KEYS } from 'src/enums';
import { MatchesRequest, StatusToSend } from 'src/models/general';
// type FaledDeleteMutaionReturn = {
//   prevData: MatchedMemberItemModel[] | undefined;
// }

export const useMatchedMembers = (
  jobId: number,
  status?: MemberStatus | StatusToSend | null,
) => {
  const queryClient = useQueryClient();

  const {
    data, isLoading, isError,
  } = useQuery(
    [QUERY_KEYS.PROVIDER_JOB_MATCHES, jobId, status || 'All'],
    () => fetchMatchedMembers(jobId, status),
  );

  const { mutate: deleteMatch } = useMutation<void, Error, number>(
    'delete-matching',
    (memberId) => deleteMatched(+jobId, memberId),
    {
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.PROVIDER_JOB_MATCHES);
      },
    },
  );

  return {
    data,
    isLoading,
    deleteMatch,
    matchedCount: data?.length || 0,
    isError,
  };
};

interface SearchQueryParamsTypes {
  search: string;
  page: string;
  selectedProfession: string;
  selectedSkills: string[];
}

export const useMembersToMatch = (
  jobId: number,
  searchParams: SearchQueryParamsTypes,
) => {
  const {
    page, search, selectedProfession, selectedSkills,
  }
    = searchParams || {};

  const {
    data, isLoading,
  } = useQuery(
    [
      'memberts-to-match',
      jobId,
      search,
      page,
      selectedProfession,
      selectedSkills,
    ],
    () =>
      fetchMembersToMatch(jobId, {
        page: +page || 1,
        search,
        limit: QUERY_LIMIT,
        profession:
          selectedProfession || selectedProfession === '0'
            ? selectedProfession.toString()
            : '',
        skills: selectedSkills || [],
      }),
  );

  return {
    members: data?.results,
    count: data?.count || 0,
    next: data?.next || false,
    previous: data?.previous || false,
    isLoading,
  };
};

export const useCreateMatching = (jobId: string) => {
  const queryClient = useQueryClient();
  const {
    mutate, isLoading,
  } = useMutation<
    { detail: string; },
    Error,
    { hires: MatchesRequest[]; }
  >('create-matching', (props) => createMatches(+jobId, props), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PROVIDER_JOB_MATCHES]);
    },
  });

  return {
    mutate,
    isLoading,
  };
};

export default useMatchedMembers;
