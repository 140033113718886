import React, { lazy } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/context/authContext';
import UserDescriptionWrapper from '../userDescription/userDescriptionWrapper';

const UserDescriptionPage = lazy(() => import('src/components/pages/userDescription'));

const DynamicUserDescriptionWrapper = () => {
  const {
    userRole = '', hash = '',
  } = useParams<{ userRole: string; hash: string;}>();
  const {
    isLoggedIn, role,
  } = useAuth();

  if (!isLoggedIn) {
    return (
      <UserDescriptionWrapper>
        <UserDescriptionPage />
      </UserDescriptionWrapper>
    );
  }


  const userDescriptionPath = {
    client: `/client/requests/${userRole}/${hash}`,
    freelancer: `/freelancer/${userRole}/${hash}`,
    service_provider: `/service/${userRole}/${hash}`,
  };
  const newPath = role ? userDescriptionPath[role] : '/';
  return <Navigate to={ newPath } />;
};

export default DynamicUserDescriptionWrapper;
