import { useMutation, useQuery, useQueryClient } from 'react-query';
import { changeJobStatus, deleteJob, fetchJobById } from 'src/api';
import { QUERY_KEYS } from 'src/enums';
import { JobModelWithClientData } from 'src/models/general';

type StatusMutationReturn = {
  prevData: JobModelWithClientData | undefined;
};

const JOB_QUERY_KEY = 'job';

interface IJob {
  jobId: string | null;
  onSuccess?: (data: JobModelWithClientData) => void;
}

export const useJob = ({
  jobId, onSuccess,
}: IJob) => {
  const queryClient = useQueryClient();

  const {
    data, isLoading, isError,
  } = useQuery(
    [QUERY_KEYS.JOB, jobId],
    () => fetchJobById(jobId),
    {
      enabled: !!jobId,
      onSuccess,
    },
  );

  const { mutate: changeStatus } = useMutation<
    JobModelWithClientData,
    Error,
    { id: number; is_published?: boolean; },
    StatusMutationReturn
  >('change-job-status', (props) => changeJobStatus(props), {
    onSuccess: (newJob) => {
      queryClient.setQueryData<JobModelWithClientData | undefined>(
        [QUERY_KEYS.JOB, jobId],
        newJob,
      );
      queryClient.invalidateQueries([QUERY_KEYS.JOBS]);
    },
    onError: (_err, __props, context) => {
      queryClient.setQueryData([JOB_QUERY_KEY, jobId], context?.prevData);
    },
  });

  return {
    job: data,
    isLoading,
    changeStatus,
    isError,
  };
};

export const useDeleteJob = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<void, Error, string>(
    'delete-job',
    (id) => deleteJob(String(id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.JOBS);
      },
    },
  );

  return {
    deleteJobById: mutate,
  };
};

export default useJob;
